import React, { useState } from "react";
import { Grid, Box, Container } from "@mui/material";
import NavbarLoggedInNew from "components/Navbar-new/NavbarLoggedInNew";
import MenteeCardList from "./MenteeCards";
import MenteeDashboardCard from "./MenteeDashboardCard";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.data?.role;
  const [isDrawerOpen, setIsDrawerOpen] = useState(true); // Set to true to open by default

  return (
    <>
      <NavbarLoggedInNew 
        isDrawerOpen={isDrawerOpen} 
        setIsDrawerOpen={setIsDrawerOpen} 
        userRole={userRole} 
      />
      
      <Container maxWidth="xl" sx={{ mt: 4 }}>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {userRole === "mentor" ? (
              <MenteeCardList />
            ) : (
              <MenteeDashboardCard />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;

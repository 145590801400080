import React, { useState, useEffect } from "react";
import Split from "react-split";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeLight } from "@uiw/codemirror-theme-vscode";
import { javascript } from "@codemirror/lang-javascript";
import { cpp } from "@codemirror/lang-cpp";
import { python } from "@codemirror/lang-python";
import { go } from "@codemirror/lang-go";
import { java } from "@codemirror/lang-java";
import { Button } from "react-bootstrap";
import { Divider } from "@mui/material";
import PreferenceNav from "./PreferenceNavbar/PreferenceNavbar";
import ProblemDiscription from "../ProblemDiscription/ProblemDiscription";
import NavigationBar from "../../NavigationBar/NavigationBar";
import "./Playground.css";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import ErrorToast from "components/Toaster/ErrorToast";
import api from "../../../api";

const Workspace = () => {
  const [language, setLanguage] = useState("python");
  const [code, setCode] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const selectedModuleId = useSelector((state) => state.module.selectedModuleId);

  const boilerPlates = {
    javascript: "// Write your JavaScript code here",
    cpp: "// Write your C++ code here",
    python: "# Write your Python code here",
    java: "// Write your Java code here",
    go: "// Write your Go code here",
  };

  const languageExtensions = {
    javascript: javascript(),
    cpp: cpp(),
    python: python(),
    java: java(),
    go: go(),
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const response = await api.get(`course/${selectedModuleId}/p/`);
        const fetchedQuestions = response?.data || [];
        setQuestions(fetchedQuestions);
        
        if (fetchedQuestions.length > 0) {
          const firstQuestion = fetchedQuestions[0];
          const firstSubmission = firstQuestion.submission_data?.[0];
          
          if (firstSubmission) {
            setLanguage(firstSubmission.language);
            setCode(firstSubmission.submission);
          } else {
            setCode(boilerPlates[language]);
          }
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [selectedModuleId]);

  // Load new question and maintain language if there's submission data
  useEffect(() => {
    const currentQuestion = questions[currentQuestionIndex];
    const submission = currentQuestion?.submission_data?.[0];

    if (submission) {
      setLanguage(submission.language);
      setCode(submission.submission);
    } else if (code === "") {
      setCode(boilerPlates[language]);
    }
  }, [currentQuestionIndex, questions]);

  const handleSubmit = async () => {
    const currentQuestion = questions[currentQuestionIndex];
    if (!currentQuestion) return;

    try {
      await api.post(`course/${selectedModuleId}/p/${currentQuestion.id}/`, {
        submission: code,
        language,
      });
      const updatedQuestions = questions.map((question, index) =>
        index === currentQuestionIndex
          ? {
              ...question,
              submitted: true,
              submission_data: [{ submission: code, language }],
            }
          : question
      );
      setQuestions(updatedQuestions);
    } catch (error) {
      setError(error);
    }
  };

  const handleCodeChange = (value) => setCode(value);

  const handleNext = async () => {
    await handleSubmit();
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };
  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  
    const currentQuestion = questions[currentQuestionIndex];
    const languageSubmission = currentQuestion?.submission_data?.find(
      (submission) => submission.language === newLanguage
    );
  
    // Use the boilerplate code if no submission exists for the selected language
    if (languageSubmission) {
      setCode(languageSubmission.submission);
    } else {
      setCode(boilerPlates[newLanguage]);
    }
  };
  
  
  

  if (loading) return <Loader />;
  if (error) return <div className="error-message"><p>{error.message}</p></div>;

  const currentExtension = languageExtensions[language] || python();


  return (
    <div>
      <NavigationBar
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions.length}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
      <Divider sx={{ borderColor: "black", borderWidth: "1px", width: "100%", marginBottom: "0px" }} />
      <Split className="split" minSize={500} sizes={[50, 50]}>
        <div className="split-pane">
          <ProblemDiscription problem={questions[currentQuestionIndex]} />
        </div>
        <div className="split-pane-code">
          <div className="playground-container">
            <PreferenceNav setLanguage={handleLanguageChange} initialLanguage={language} />
            
            
            <div className="code-editor">
              <CodeMirror
                key={`${language}-${currentQuestionIndex}`}
                value={code}
                theme={vscodeLight}
                extensions={[currentExtension]}
                style={{ fontSize: 16, height: "calc(75vh - 2.75rem)" }}
                onChange={handleCodeChange}
              />
            </div>
            <div className="button-container">
              <Button className="button" onClick={handleSubmit}>Submit Code</Button>
            </div>
          </div>
        </div>
      </Split>
    </div>
  );
  
};


export default Workspace;

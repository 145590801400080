import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import AIIcon from "../assets/homepage/artificialAI.svg";
import path from "../assets/homepage/path.svg";
import mentor from "../assets/homepage/mentorIcon.svg";
import engineer from "../assets/homepage/softwareEngineering.svg";
import WhoIsItForContent from "Homepage/components/WhoIsItContent";
import EdgentOfferings from "./EdgentOfferings";
import HowItWorks from "./HowItWorks";
import MentorshipSection from "./Testmonials";
import Footer from "./Footer";
import CallToAction from "./CallToAction";
import { Link } from "react-router-dom";
import backgroundImage from "../assets/homepage/backgroundImage.svg";
import edgentWhite from "../assets/homepage/edgentWhite.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ErrorToast from "components/Toaster/ErrorToast";
import ViewProgramPopup from "./ViewProgramPopup";
import RegisterPopup from "./RegisterPopup";
import TopAlert from "./TopAlert";
import PersonIcon from '@mui/icons-material/Person';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CodeIcon from '@mui/icons-material/Code';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import WorkIcon from '@mui/icons-material/Work';
import BuildIcon from '@mui/icons-material/Build';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

const HeroContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 6),
  textAlign: "center",
}));

const Card = styled(Paper)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const HomepageNew = () => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");
  const [loading, setLoading] = useState(false);
  const [loadingAI, setLoadingAI] = useState(false);
  const [loadingSDE, setLoadingSDE] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openSDEModal, setSDEOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);

  const aiMlItems = [
    {
      icon: <PersonIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Personalized AI Mentorship",
      description: "Experience 1-to-1 mentorship with a dedicated AI expert. Your mentor will craft a personalized learning plan, guide you through every step of the course, and provide continuous support via individual calls. It's just you and your mentor, ensuring focused, tailored guidance to achieve your AI goals effectively."
    },
    {
      icon: <MenuBookIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Comprehensive Learning Resources",
      description: "Gain access to expertly written books, industry-standard assessment tests, and hands-on assignments for every module. These resources are designed to deepen your understanding, test your knowledge, and provide practical experience, ensuring you're fully prepared for real-world AI challenges."
    },
    {
      icon: <BuildIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Real-World Project Experience",
      description: "Collaborate with startups and research groups to work on real-world projects. Gain practical experience, apply your skills to solve real challenges, and build a portfolio that showcases your expertise and readiness for the industry."
    },
    {
      icon: <DataUsageIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Comprehensive Data Science Curriculum",
      description: "Start from the basics of Python programming and advance to mastering mathematics for data science, machine learning, deep learning, NLP, computer vision, generative AI, and AI engineering. Become a complete data scientist in less than 6 months, equipped to excel in the competitive world of AI and data science."
    },
    {
      icon: <WorkOutlineIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Mock Interviews and Referrals",
      description: "Prepare for success with mock interviews tailored to industry standards and gain valuable feedback to sharpen your skills. Unlock opportunities with referral support, connecting you to top companies and startups to kickstart or elevate your career in AI and data science."
    },
  ];
  
  const sdeItems = [
    {
      icon: <PersonIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Personalized SDE Mentorship",
      description: "Experience 1-to-1 mentorship with a dedicated software development expert. Your mentor will craft a customized learning plan, guide you through every step of the course, and provide ongoing support via individual calls, ensuring focused and personalized guidance throughout your journey."
    },
    {
      icon: <MenuBookIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Comprehensive Learning Resources",
      description: "Access expertly written books, industry-standard assessment tests, and assignments tailored for each module. These resources are designed to strengthen your coding skills, test your knowledge, and provide hands-on experience to prepare you for real-world challenges in software development."
    },
    {
      icon: <CodeIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Real-World Project Experience",
      description: "Collaborate with startups and tech teams to work on real-world software development projects. Apply your skills, solve practical problems, and build an impressive portfolio that showcases your expertise to potential employers."
    },
    {
      icon: <DeveloperModeIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Comprehensive SDE Curriculum",
      description: "Start from the basics of coding and algorithms, progress through advanced data structures, system design, and backend/frontend development. Master cutting-edge technologies and frameworks, becoming a complete software developer ready to ace any challenge in less than 6 months."
    },
    {
      icon: <WorkIcon color="white" sx={{ fontSize: '40px' }} />,
      title: "Mock Interviews and Referrals",
      description: "Get industry-focused mock interviews with detailed feedback to refine your skills and boost your confidence. Benefit from referral support to top tech companies and startups, giving you a competitive edge in landing your dream software development role."
    },
  ];
  

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleJoinNow = () => {
    if (!accessToken) {
      navigate("/login");
    } else {
      navigate("/calendar-meeting");
    }
  };
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://restcountries.com/v3.1/all?fields=name"
        );
        setCountries(response.data);
      } catch (error) {
        <ErrorToast message={`Error fetching countries: ${error}`} />;
      }
    };

    fetchCountries();
  }, []);

  return (
    <div>
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: { sm: "500px", xs: "700px", lg: "700px", md: "700px" },
          position: "relative",
        }}
      >
        <TopAlert onClick={() => setRegisterOpen(true)} />
        <AppBar
          position="static"
          sx={{
            background: "transparent",
            boxShadow: "none",
          }}
        >
          <Toolbar
            sx={{ mt: 3, display: "flex", justifyContent: "space-between", alignItems: 'center' }}
          >
            {/* Logo */}
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box
                component="img"
                src={edgentWhite}
                alt="Edgent Logo"
                sx={{
                  height: { lg: "60px", md: "60px", sm: "40px", xs: "40px" },
                  // ml: { lg: "40px", md: "40px", sm: "20px", xs: "20px" },
                }}
              />
            </Link>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // mr: { lg: "40px", md: "40px", sm: "20px", xs: "20px" },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "white",
                  color: "#0181BB",
                  fontFamily: "Be Vietnam Pro",
                  borderRadius: "50px",
                  border: "1px solid #0181BB",
                  "&:hover": {
                    backgroundColor: "#E0F3FA", // Light blue hover effect
                    color: "#086690", // Darker blue text on hover
                  },
                }}
              onClick={handleJoinNow}
              >
                Sign In
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <HeroContent>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {/* <Box
              component="img"
              src={edgentWhite}
              alt="Edgent Logo"
              sx={{
                height: { xs: "60px", md: "80px" }, 
                mb: 2, 
              }}
            /> */}
            <Typography
              align="center"
              sx={{
                fontWeight: 600,
                color: "white",
                fontSize: { xs: "45px", md: "56px" },
                fontFamily: "Be Vietnam Pro",
                mb: 1,
              }}
            >
              1:1 Mentorship
            </Typography>
            <Typography
              align="center"
              sx={{
                fontWeight: 600,
                color: "white",
                fontSize: { xs: "45px", md: "56px" },
                fontFamily: "Be Vietnam Pro",
              }}
            >
              Tailored for Success
            </Typography>
            <Typography
              align="center"
              gutterBottom
              mt={2}
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                color: "white",
                fontWeight: 400,
                width: "100%",
                textAlign: "center",
                fontFamily: "Be Vietnam Pro",
                marginTop: "16px",
              }}
            >
              Unlock your dream career through a personalized mentorship program
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: "auto",
                fontSize: "18px",
                borderRadius: "50px",
                background: "linear-gradient(90deg, #0181BB 0%, #086690 100%)",
                color: "white",
                textTransform: "capitalize",
                fontFamily: "Be Vietnam Pro",
                marginTop: "16px",
              }}
              onClick={() => setRegisterOpen(true)}
              // onClick={handleJoinNow}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Register for Free"
              )}
            </Button>
            <RegisterPopup
              open={registerOpen}
              onClose={() => setRegisterOpen(false)}
            />
          </Container>
        </HeroContent>
      </Box>
      <Container
        sx={{
          pt: 8,
          pb: 8,
          position: "relative",
          maxWidth: "100%",
        }}
      >
        <Grid
          container
          justifyContent="center"
          width="100%"
          columnGap={3}
          sx={{
            "@media (min-width: 900px)": {
              position: "absolute",
              bottom: 50,
              left: 8,
            },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            justifyContent="center"
            mb={{ xs: 4, sm: 2 }}
          >
            <Card sx={{ borderRadius: "12px" }}>
              <Box
                p={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                columnGap={{ xs: 0, sm: 1 }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    src={AIIcon}
                    alt="AI"
                    style={{ width: "170px", height: "220px" }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems={{ xs: "center", sm: "flex-start" }}
                  mt={{ xs: 2, sm: 0 }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "22px" },
                      fontWeight: 600,
                      color: "#202124",
                      fontFamily: "Be Vietnam Pro",
                      textAlign: { xs: "center", sm: "left" },
                    }}
                  >
                    Artificial Intelligence & Machine Learning
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "center", sm: "flex-start" },
                      mt: 2,
                    }}
                    columnGap={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: { xs: 1, sm: 0 },
                      }}
                    >
                      <img
                        src={mentor}
                        alt="Mentor"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#595A5F",
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        With Mentor
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={path}
                        alt="Path"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#595A5F",
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        Personalized Paths
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "#DDF4FFBA",
                      color: "#0181BB",
                      fontSize: "14px",
                      textTransform: "capitalize",
                      borderRadius: "6px",
                      width: "140px",
                      mt: 2,
                      fontFamily: "Be Vietnam Pro",
                    }}
                    onClick={() => setOpen(true)}
                    disabled={loadingAI}
                  >
                    {loadingAI ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      "View Program"
                    )}
                  </Button>
                </Box>
              </Box>
            </Card>

            <ViewProgramPopup
              title="Program Offerings"
              items={aiMlItems}
              open={open}
              onClose={() => setOpen(false)}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            justifyContent="center"
            mb={{ xs: 4, sm: 2 }}
          >
            <Card sx={{ borderRadius: "12px" }}>
              <Box
                p={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                columnGap={{ xs: 0, sm: 1 }}
              >
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    src={engineer}
                    alt="Engineer"
                    style={{ width: "170px", height: "220px" }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems={{ xs: "center", sm: "flex-start" }}
                  mt={{ xs: 2, sm: 0 }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "22px" },
                      fontWeight: 600,
                      color: "#202124",
                      fontFamily: "Be Vietnam Pro",
                      textAlign: { xs: "center", sm: "left" },
                    }}
                  >
                    Software Development Engineering
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: { xs: "center", sm: "flex-start" },
                      mt: 2,
                    }}
                    columnGap={1}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: { xs: 1, sm: 0 },
                      }}
                    >
                      <img
                        src={mentor}
                        alt="Mentor"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#595A5F",
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        With Mentor
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={path}
                        alt="Path"
                        style={{
                          width: "18px",
                          height: "18px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#595A5F",
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        Personalized Paths
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      backgroundColor: "#DDF4FFBA",
                      color: "#0181BB",
                      fontSize: "14px",
                      textTransform: "capitalize",
                      borderRadius: "6px",
                      width: "140px",
                      mt: 2,
                      fontFamily: "Be Vietnam Pro",
                    }}
                    onClick={() => setSDEOpenModal(true)}
                    disabled={loadingSDE}
                  >
                    {loadingSDE ? (
                      <CircularProgress size={20} color="primary" />
                    ) : (
                      "View Program"
                    )}
                  </Button>
                </Box>
              </Box>
            </Card>

            <ViewProgramPopup
              title="Program Offerings"
              items={sdeItems}
              open={openSDEModal}
              onClose={() => setSDEOpenModal(false)}
            />
          </Grid>
        </Grid>
      </Container>
      <Box mt={"50px"}>
        <WhoIsItForContent />
      </Box>
      <Box mt={"120px"}>
        <EdgentOfferings />
      </Box>
      <Box mt={"120px"} sx={{ position: "relative" }}>
        <HowItWorks />
      </Box>
      <Box mt={"120px"} sx={{ backgroundColor: "#F1F6F9" }}>
        <MentorshipSection />
      </Box>
      <Box sx={{ backgroundColor: "#0181BB" }}>
        <CallToAction />
      </Box>
      <Box>
        <Footer />
      </Box>
    </div>
  );
};

export default HomepageNew;

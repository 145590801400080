import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RegisterImage from "../assets/homepage/register.jpeg";
import axios from "axios";
import { baseURL } from "aws-config";

const RegisterPopup = ({ open, onClose }) => {
  const [program, setProgram] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({
    program: "",
    name: "",
    email: "",
    phone: "",
  });
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = { program: "", name: "", email: "", phone: "" };

    if (!program) {
      newErrors.program = "Program is required.";
      formIsValid = false;
    }

    if (!name) {
      newErrors.name = "Name is required.";
      formIsValid = false;
    }

    if (!email) {
      newErrors.email = "Email is required.";
      formIsValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is not valid.";
      formIsValid = false;
    }

    if (!phone) {
      newErrors.phone = "Phone number is required.";
      formIsValid = false;
    } else if (!/^\d{10}$/.test(phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const requestData = {
        name,
        email,
        program_name:
          program === "artificialIntelligence"
            ? "Artificial Intelligence & Machine Learning"
            : "Software Development",
        phone,
      };

      try {
        const response = await axios.post(
          `${baseURL}request-call-details/`,
          requestData
        );

        // Show success toast
        setToastMessage("Form submitted successfully!");
        setToastOpen(true);

        setProgram("");
        setName("");
        setEmail("");
        setPhone("");
        onClose();
      } catch (error) {
        // Show error toast
        setToastMessage(`Error: ${error.response?.data?.message || error.message}`);
        setToastOpen(true);
      }
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95%",
            maxWidth: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 2,
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography
              variant="h5"
              color="#0181BB"
              fontWeight="bold"
              fontFamily="Be Vietnam Pro"
            >
              Book Your Trial
            </Typography>
            <CloseIcon onClick={onClose} style={{ cursor: "pointer" }} />
          </Box>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  maxHeight: "100%",
                  bgcolor: "#F7FCFF",
                  borderRadius: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <img
                  src={RegisterImage}
                  alt="Left Side"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <Box
                  position="absolute"
                  left="10%"
                  top="20%"
                  bgcolor="#E5F6FB"
                  p={1}
                  borderRadius="12px"
                  fontFamily="Be Vietnam Pro"
                >
                  Career Switch
                </Box>
                <Box
                  position="absolute"
                  right="10%"
                  top="50%"
                  bgcolor="#E5F6FB"
                  p={1}
                  borderRadius="12px"
                  fontFamily="Be Vietnam Pro"
                >
                  Promotion
                </Box>
                <Box
                  position="absolute"
                  left="10%"
                  bottom="20%"
                  bgcolor="#E5F6FB"
                  p={1}
                  borderRadius="12px"
                  fontFamily="Be Vietnam Pro"
                >
                  Salary Hike
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                component="form"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <TextField
                  fullWidth
                  select
                  label="Select Program"
                  value={program}
                  onChange={(e) => setProgram(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  sx={{ fontFamily: "Be Vietnam Pro" }}
                  error={!!errors.program}
                  helperText={errors.program}
                >
                  <MenuItem value="artificialIntelligence">
                    Artificial Intelligence & Machine Learning
                  </MenuItem>
                  <MenuItem value="softwareDevelopment">
                    Software Development Engineering
                  </MenuItem>
                </TextField>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  margin="normal"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <TextField
                  fullWidth
                  label="Phone"
                  variant="outlined"
                  margin="normal"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="I agree to receive SMS & WhatsApp communications on this number."
                  sx={{
                    mt: 1,
                    ".MuiTypography-root": {
                      fontSize: "12px !important",
                      fontFamily: "Be Vietnam Pro",
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "100%",
                    fontSize: "18px",
                    borderRadius: "50px",
                    background:
                      "linear-gradient(90deg, #0181BB 0%, #086690 100%)",
                    color: "white",
                    textTransform: "capitalize",
                    fontFamily: "Be Vietnam Pro",
                    mt: "10px",
                  }}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Book Your Call
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        onClose={() => setToastOpen(false)}
      >
        <Alert
          onClose={() => setToastOpen(false)}
          severity={toastMessage.includes("Error") ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegisterPopup;

import React, { useState, useEffect } from "react";
import axios, { post } from "axios";
import { Modal, Button, Form, Card } from "react-bootstrap";
import Select from "react-select";
import { useAuth } from "../../AuthContext";
import NavbarLoggedIn from "./NavbarLoggedIn";
import { baseURL } from "../../aws-config";
import NavbarLoggedInNew from "components/Navbar-new/NavbarLoggedInNew";
import Loader from "../../components/Loader";
import ErrorToast from "components/Toaster/ErrorToast";
import SuccessToast from "components/Toaster/SuccesToast";
import api from '../../api'

const ModalComponent = ({ showModal, handleCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    country: "",
    state: "",
    currentlyWorking: "",
    yearsOfExperience: "",
    currentProfession: "",
    targetProfession: "",
    targetCompany: "",
    mentorCompany: "",
    college_name: "",
    school_marks: "",
    // cv: null,
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  const professionOptions = [
    {
      value: "Software Engineer - Frontend",
      label: "Software Engineer - Frontend",
    },
    {
      value: "Software Engineer - Backend",
      label: "Software Engineer - Backend",
    },
    {
      value: "Software Engineer - Fullstack",
      label: "Software Engineer - Fullstack",
    },
    {
      value: "Data Engineer/ Data Scientist",
      label: "Data Engineer/ Data Scientist",
    },
    { value: "Other IT", label: "Other IT" },
    { value: "Analyst", label: "Analyst" },
    { value: "Non-IT", label: "Non-IT" },
    { value: "N/A", label: "N/A" },
  ];

  const targetProfessionOptions = [
    {
      value: "Software Engineer - Frontend",
      label: "Software Engineer - Frontend",
    },
    {
      value: "Software Engineer - Backend",
      label: "Software Engineer - Backend",
    },
    {
      value: "Software Engineer - Fullstack",
      label: "Software Engineer - Fullstack",
    },
    {
      value: "Data Engineer/ Data Scientist",
      label: "Data Engineer/ Data Scientist",
    },
    { value: "Other IT", label: "Other IT" },
    { value: "Analyst", label: "Analyst" },
    { value: "Non-IT", label: "Non-IT" },
  ];

  const topCompanies = [
    {
      value: "Microsoft",
      label: "Microsoft",
    },
    {
      value: "Apple",
      label: "Apple",
    },
    {
      value: "Alphabet (Google)",
      label: "Alphabet (Google)",
    },
    {
      value: "Amazon",
      label: "Amazon",
    },
    { value: "Meta Platforms (Facebook)", label: "Meta Platforms (Facebook)" },
    { value: "Netflix", label: "Netflix" },
    { value: "Cisco", label: "Cisco" },
    { value: "Adobe", label: "Adobe" },
    { value: "Oracle", label: "Oracle" },
    { value: "Salesforce", label: "Salesforce" },
    { value: "IBM", label: "IBM" },
    { value: "Accenture", label: "Accenture" },
    { value: "NVIDIA", label: "NVIDIA" },
    { value: "TSMC", label: "TSMC" },
    { value: "Intel", label: "Intel" },
    { value: "HP enterprise", label: "HP enterprise" },
    { value: "Cognizant", label: "Cognizant" },
    { value: "Capgemini", label: "Capgemini" },
    { value: "Dell", label: "Dell" },
    { value: "Intuit", label: "Intuit" },
    { value: "SAP", label: "SAP" },
    { value: "Wipro", label: "Wipro" },
    { value: "TCS", label: "TCS" },
    { value: "No preference", label: "No preference" },
  ];

  const currentlyWorkingOptions = [
    { value: "Pursuing Graduation", label: "Pursuing Graduation" },
    { value: "An Unemployed Graduate", label: "An Unemployed Graduate" },
    { value: "A Young Professional", label: "A Young Professional" },
    { value: "Not working", label: "Not working" },
    { value: "Others", label: "Others" },
  ];

  const yearsOfExperienceOptions = [
    { value: "1", label: "< 1 Year" },
    { value: "1-2", label: "1 Year - 2 Years" },
    { value: "2-3", label: "2 Years - 3 Years" },
    { value: "3-5", label: "3 Years - 5 Years" },
    { value: "5", label: "> 5 Years" },
  ];

  // const countryOptions = countries?.map((country) => ({
  //   value: country.name.common,
  //   label: country.name.common,
  // }));

  const countryOptions = countries?.map((country) => {
    const callingCode = country.idd?.suffixes?.[0];

    if (callingCode) {
      return {
        value: country.name.common,
        label: `${country.name.common} (${country.idd.root}${callingCode})`,
        code: `+${country.idd.root}${callingCode}`,
      };
    } else {
      return {
        value: country.name.common,
        label: `${country.name.common} (N/A)`,
        code: "N/A",
      };
    }
  });

  const stateOptions = states?.map((state) => ({
    value: state.name,
    label: state.name,
  }));

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(
          "https://restcountries.com/v3.1/all?fields=name,idd"
        );
        setCountries(response.data);
      } catch (error) {
        <ErrorToast message={`Error fetching countries ${error}`}/>
      }
    };

    fetchCountries();
  }, []);

  const fetchStates = async (selectedCountry) => {
    try {
      const response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/states",
        {
          country: selectedCountry,
        }
      );

      const stateData = response?.data?.data?.states;
      if (stateData) {
        setStates(stateData);
      } else {
        setStates([]);
      }
    } catch (error) {
      <ErrorToast message={`Error fetching states ${error}`}/>
    }
  };

  const handleChange = (selectedOption, { name }) => {
    const { value } = selectedOption;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      currentProfession:
        name === "currentProfession" ? value : prevData.currentProfession,
      currentlyWorking:
        name === "currentlyWorking" ? value : prevData.currentlyWorking,
    }));

    // Check if the selected option is for the country
    if (name === "country") {
      const selectedCountry = value;

      // Fetch states based on the selected country
      fetchStates(selectedCountry);
    }
  };

  const handleChange1 = (selectedOption, { name, value }) => {
    const targetValue = selectedOption ? selectedOption.value : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: targetValue,
      currentProfession:
        name === "currentProfession" ? targetValue : prevData.currentProfession,
      currentlyWorking:
        name === "currentlyWorking" ? targetValue : prevData.currentlyWorking,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true before API call
  
    const apiUrl = `${baseURL}user-profile/`;
  
    const requestBody = {
      first_name: formData?.firstName,
      phone_number: formData?.phoneNumber,
      country: formData?.country,
      state: formData?.state,
      have_job: formData?.currentlyWorking === "Yes",
      years_of_experience: parseInt(formData?.yearsOfExperience),
      current_profession: formData?.currentProfession,
      target_profession: formData?.targetProfession,
      target_company: formData?.targetCompany,
      mentor_preferred_company: formData?.mentorCompany,
      college_name: formData?.college_name,
      school_marks: formData?.school_marks,
    };
  
    try {
      const response = await api.post(apiUrl, requestBody);
      <SuccessToast message="Successfully submitted the form." />;
      window.location.href = "/calendar-meeting";
    } catch (error) {
      <ErrorToast message={`Something went wrong: ${error.message}`} />;
    } finally {
      setLoading(false); // Set loading to false after API call completes
    }
  };
  
  

  return (
    <>
      {loading && <Loader />}
      <div className="w-100 h-80 d-flex justify-content-center align-items-center">
        <Card className="w-100 p-3 mt-4" style={{ maxWidth: "700px" }}>
          <Card.Title>Registration Form</Card.Title>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <Form.Group
                  controlId="firstName"
                  className="col-12 col-sm-6 px-1"
                >
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    value={formData?.firstName}
                    onChange={(e) =>
                      handleChange1(null, {
                        name: "firstName",
                        value: e.target.value,
                      })
                    }
                    required
                    minLength={3}
                  />
                </Form.Group>
                <Form.Group
                  controlId="lastName"
                  className="col-12 col-sm-6 px-1"
                >
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    value={formData?.lastName}
                    onChange={(e) =>
                      handleChange1(null, {
                        name: "lastName",
                        value: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
              </div>

              <Form.Group controlId="phoneNumber" className="mt-3">
                <Form.Label>Phone Number</Form.Label>
                <div className="input-group">
                  <Form.Control
                    type="tel"
                    name="phoneNumber"
                    value={formData?.phoneNumber}
                    onChange={(e) =>
                      handleChange1(null, {
                        name: "phoneNumber",
                        value: e.target.value,
                      })
                    }
                    required
                    minLength={7}
                    maxLength={15}
                    pattern="[0-9]*"
                    onKeyDown={(e) => {
                      const pattern = /[0-9]/;
                      const inputChar = String.fromCharCode(e.keyCode);
                      if (!pattern.test(inputChar) && e.keyCode !== 8) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </Form.Group>

              <div className="row">
                <Form.Group
                  controlId="country"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>Country</Form.Label>
                  <Select
                    name="country"
                    value={countryOptions.find(
                      (option) => option.value === formData?.country
                    )}
                    onChange={(selectedOption) =>
                      handleChange(selectedOption, { name: "country" })
                    }
                    options={countryOptions}
                    isSearchable
                    placeholder="Select Country"
                    required
                  />
                </Form.Group>
                <Form.Group
                  controlId="state"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>State</Form.Label>
                  <Select
                    name="state"
                    value={
                      stateOptions?.length
                        ? stateOptions.find(
                            (option) => option.value === formData?.state
                          )
                        : ""
                    }
                    onChange={(selectedOption) =>
                      handleChange(selectedOption, { name: "state" })
                    }
                    options={stateOptions}
                    isSearchable
                    placeholder="Select State"
                    required
                  />
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group
                  controlId="college_name"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>College</Form.Label>
                  <Form.Control
                    type="text"
                    name="college_name"
                    value={formData?.college_name}
                    onChange={(e) =>
                      handleChange1(null, {
                        name: "college_name",
                        value: e.target.value,
                      })
                    }
                    required
                  />
                </Form.Group>
                <Form.Group
                  controlId="twelfthMarks"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>12th Marks</Form.Label>
                  <Form.Control
                    type="number"
                    name="school_marks"
                    value={formData?.school_marks}
                    onChange={(e) =>
                      handleChange1(null, {
                        name: "school_marks",
                        value: e.target.value,
                      })
                    }
                    required
                    min={1}
                    max={100}
                  />
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group
                  controlId="currentlyWorking"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>Are you currently</Form.Label>
                  <Select
                    name="currentlyWorking"
                    value={currentlyWorkingOptions.find(
                      (option) => option.value === formData?.currentlyWorking
                    )}
                    onChange={(selectedOption) =>
                      handleChange(selectedOption, { name: "currentlyWorking" })
                    }
                    options={currentlyWorkingOptions}
                    isSearchable
                    placeholder="Select your current status"
                    required
                  />
                </Form.Group>
                <Form.Group
                  controlId="yearsOfExperience"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>Years of Work Experience</Form.Label>
                  <Select
                    name="yearsOfExperience"
                    value={yearsOfExperienceOptions.find(
                      (option) => option.value === formData?.yearsOfExperience
                    )}
                    onChange={(selectedOption) =>
                      handleChange(selectedOption, {
                        name: "yearsOfExperience",
                      })
                    }
                    options={yearsOfExperienceOptions}
                    isSearchable
                    placeholder="Select your Years of Experience"
                    required
                  />
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group
                  controlId="currentProfession"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>Current Profession</Form.Label>
                  <Select
                    name="currentProfession"
                    value={professionOptions.find(
                      (option) => option.value === formData?.currentProfession
                    )}
                    onChange={(selectedOption) =>
                      handleChange(selectedOption, {
                        name: "currentProfession",
                      })
                    }
                    options={professionOptions}
                    isSearchable
                    placeholder="Select your profession"
                    required
                  />
                </Form.Group>
                <Form.Group
                  controlId="targetProfession"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>Target Profession </Form.Label>
                  <Select
                    name="targetProfession"
                    value={targetProfessionOptions.find(
                      (option) => option.value === formData?.targetProfession
                    )}
                    onChange={(selectedOption) =>
                      handleChange(selectedOption, { name: "targetProfession" })
                    }
                    options={targetProfessionOptions}
                    isSearchable
                    placeholder="Select target profession"
                    required
                  />
                </Form.Group>
              </div>

              <div className="row">
                <Form.Group
                  controlId="targetCompany"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>Target Company </Form.Label>
                  <Select
                    name="targetCompany"
                    value={topCompanies.find(
                      (option) => option.value === formData?.targetCompany
                    )}
                    onChange={(selectedOption) =>
                      handleChange(selectedOption, { name: "targetCompany" })
                    }
                    options={topCompanies}
                    isSearchable
                    placeholder="Select target profession"
                    required
                  />
                </Form.Group>
                <Form.Group
                  controlId="mentorCompany"
                  className="col-12 col-sm-6 px-1 mt-3"
                >
                  <Form.Label>Desired Company of your Mentor</Form.Label>
                  <Select
                    name="mentorCompany"
                    value={topCompanies.find(
                      (option) => option.value === formData?.mentorCompany
                    )}
                    onChange={(selectedOption) =>
                      handleChange(selectedOption, { name: "mentorCompany" })
                    }
                    options={topCompanies}
                    isSearchable
                    placeholder="Select Company"
                    required
                  />
                </Form.Group>
              </div>

              <Button
                variant="primary"
                type="submit"
                size="sm"
                className="mt-4 w-100"
              >
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ModalComponent;

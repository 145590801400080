import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { CardBody } from "reactstrap";
import { AccountTree, Map, AccessTime, EmojiObjects, People, FlightTakeoff } from "@mui/icons-material"; // Import MUI icons

function HowDoesItWork() {
  const cardData = [
    { id: 1, title: "We assess your career goals and assign you the perfect mentor", icon: <AccountTree style={{ fontSize: 40 }} /> },
    { id: 2, title: "The Mentor creates personalized learning roadmap for you", icon: <Map style={{ fontSize: 40 }} /> },
    { id: 3, title: "You and your mentor meet weekly to set and review learning targets", icon: <AccessTime style={{ fontSize: 40 }} /> },
    { id: 4, title: "Your Mentorship is personalized to ensure you learn everything needed to achieve your career goal", icon: <EmojiObjects style={{ fontSize: 40 }} /> },
    { id: 5, title: "After mentorship, we provide you with rich networking opportunities", icon: <People style={{ fontSize: 40 }} /> },
    { id: 6, title: "We provide you with mock interviews, CV review services, and referrals", icon: <FlightTakeoff style={{ fontSize: 40 }} /> },
  ];

  return (
    <div className="w-100 text-center m-4">
      <h3 className="py-4">How Does It Work?</h3>
      <div className="d-flex justify-content-center">
        <Row className="text-center w-75 justify-content-center">
          {cardData.map((card) => (
            <Col key={card.id} xs={12} md={6} lg={4} className="mb-3 mx-auto">
              <Card className="rounded" style={{ width: '100%', height: '200px', border: 'none', borderRadius: '10px' }}>
                <CardBody className="d-flex flex-column justify-content-center align-items-center" style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
                  <div className="mb-2">
                    {card.icon}
                  </div>
                  <div>
                    {card.title}
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}

export default HowDoesItWork;

import React from "react";
import ReactMarkdown from "react-markdown";
import "./ProblemDescription.style.css";
// import { difficultyMap } from "../../../constant/index";
import Loader from "components/Loader";

const ProblemDescription = ({ problem }) => {
  if (!problem) {
    return <Loader />;
  }

  return (
    <div className="problem-container">
      <div className="scrollable-content">
        <div className="content-padding">
          {problem ? (
            <>
              <div className="problem-meta">
                <div className="difficulty-tag">
                  {problem.difficulty === "E"
                    ? "Easy"
                    : problem.difficulty === "M"
                    ? "Medium"
                    : "Hard"}
                </div>
              </div>
              <div className="problem-header">
                <div className="problem-title">
                  <div className="problem-name">Objective</div>
                </div>
              </div>
              <div className="problem-statement">
                <ReactMarkdown className="markdown">
                  {problem.description || "No description provided."}
                </ReactMarkdown>
                <div className="problem-name">Task</div>
                <ReactMarkdown className="markdown">
                  {problem.task || "No task provided."}
                </ReactMarkdown>
              </div>
              <div className="examples">
                {problem.examples &&
                  problem.examples.map((example, index) => (
                    <div className="example" key={index}>
                      <p className="example-title">Example {index + 1}:</p>
                      <div className="example-card">
                        <pre>
                          <strong>Input:</strong> {example.input || "N/A"}{" "}
                          <br />
                          <strong>Output:</strong> {example.output || "N/A"}{" "}
                          <br />
                          {example.explanation && (
                            <>
                              <strong>Explanation:</strong>{" "}
                              <ReactMarkdown className="markdown">
                                {example.explanation}
                              </ReactMarkdown>
                            </>
                          )}
                        </pre>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="constraints">
                <div className="constraints-title">Constraints:</div>
                <ul className="constraints-list">
                  {problem.constraints &&
                    problem.constraints.map((constraint, index) => (
                      <li key={index}>
                        <ReactMarkdown className="markdown">
                          {constraint}
                        </ReactMarkdown>
                      </li>
                    ))}
                </ul>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProblemDescription;

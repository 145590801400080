import React, { useEffect } from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { useDispatch, useSelector } from 'react-redux';
import { fetchStart, fetchSuccess, fetchFailure } from '../src/new-homepage/redux/userProfileSlice';
import api from './api'

const PrivateRoutes = () => {
  const storedAccessToken = localStorage.getItem('access_token');
  const storedTokenExpiration = localStorage.getItem('token_expiration');
  const expirationTime = storedTokenExpiration ? parseInt(storedTokenExpiration) : null;
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchStart()); // Dispatch fetch start action

      try {
        const response = await api.get('user-profile/');
        dispatch(fetchSuccess(response?.data?.data)); // Dispatch fetch success action with data
      } catch (error) {
        dispatch(fetchFailure(error?.toString())); // Dispatch fetch failure action with error
      }
    };

    fetchData();
  }, [dispatch]);

  // Check if the access token is present and not expired
  if (storedAccessToken) {
    // if (accessToken && expirationTime && expirationTime > 0) {
    return <Outlet />;
  } else {
    // Redirect to login if access token is missing or expired
    return <Navigate to="/login" />;
  }
};

export default PrivateRoutes;

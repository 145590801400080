import React from 'react';
import HomepageNavbar from '../components/Navbar';
import MainContentCard from '../components/MainContentCard';
import WhoIsItForContent from '../components/WhoIsItContent';
import HowDoesItWork from '../components/HowDoesItWork';
import Steps from '../components/Steps';
import Footer from '../components/Footer';

function AboutUs() {

  return (
    <>
      <HomepageNavbar staticPage/>
      <MainContentCard staticPage/>
      <WhoIsItForContent />
      <HowDoesItWork />
      <Steps />
      <Footer />
      {/* <WhyUsContentCard /> */}
    </>
  );
}

export default AboutUs;

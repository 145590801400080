import React, { useState } from "react";
import Toaster from "./Toaster";

const ErrorToast = ({ message }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Toaster
      open={open}
      message={message}
      severity="error"
      onClose={handleClose}
    />
  );
};

const SuccessToast = ({ message }) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Toaster
      open={open}
      message={message}
      severity="success"
      onClose={handleClose}
    />
  );
};

export default SuccessToast ;

import React, { useState, useEffect } from "react";
import { collection, addDoc, getDocs, updateDoc } from "firebase/firestore";
import { getDb } from "../firebase/auth";
import { useUser } from "../context/UserContext";

function Bottom({ chat }) {
  const [firstId, setFirstId] = useState(0);
  const [secondId, setSecondId] = useState(0);
  const [newMessage, setNewMessage] = useState("");
  const [error, setError] = useState(null);
  const [db, setDb] = useState(null);
  const { role, mentorId, mentiiId } = useUser();
  useEffect(() => {
    const initializeDb = async () => {
      try {
        const dbInstance = await getDb();
        setDb(dbInstance);
      } catch (err) {
        setError(err);
      }
    };

    initializeDb();

    if (role === "mentor") {
      setFirstId(mentorId);
      setSecondId(chat.id);
    } else {
      setFirstId(chat.id);
      setSecondId(mentiiId);
    }
  }, [role, mentorId, mentiiId, chat.id]);

  useEffect(() => {
    const markMessagesAsRead = async () => {
      if (db) {
        const collectionName = `${firstId}${secondId}`;
        const messagesRef = collection(db, collectionName);
        const querySnapshot = await getDocs(messagesRef);

        querySnapshot.forEach((doc) => {
          if (doc.data().receiverId === (mentorId || mentiiId) && !doc.data().read) {
            updateDoc(doc.ref, { read: true });
          }
        });
      }
    };

    markMessagesAsRead();
  }, [db, firstId, secondId, mentorId, mentiiId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const collectionName = `${firstId}${secondId}`;

    if (newMessage.trim() !== "" && db) {
      try {
        await addDoc(collection(db, collectionName), {
          text: newMessage,
          timeStamp: new Date(),
          senderId: mentorId || mentiiId,
          read: false, // Set read status to false initially
        });
        setNewMessage("");
      } catch (error) {
        console.error("Error adding document: ", error);
        setError(error);
      }
    }
  };

  return (
    <div className="bottom">
      <form classname="form" onSubmit={handleSendMessage}>
        <div className="message-box">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="type-message"
            placeholder="Type a message..."
          />
          <button className="add-message" type="submit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              style={{ color: "rgba(1, 129, 187, 1)" }}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-send"
            >
              <line x1="22" y1="2" x2="11" y2="13"></line>
              <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
            </svg>
          </button>
        </div>
      </form>
      {error && <p style={{ color: "red" }}>Error: {error.message}</p>}
    </div>
  );
}

export default Bottom;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import UserAvatar from "components/UserAvatar/UserAvatar";
import ErrorToast from "components/Toaster/ErrorToast";

const WorkspaceSidebarNew = () => {
  const userProfile = useSelector((state) => state.userProfile.data);
  const [menteeData, setMenteeData] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams(); // Extract the ID from the URL

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A"; // Return "N/A" if the timestamp is not provided
    const date = new Date(timestamp);
    if (isNaN(date)) return "Invalid Date"; // Check if the date is valid
    return date?.toISOString()?.split("T")[0]; // Extract YYYY-MM-DD from ISO string
  };

  const handleViewButtonClick = () => {
    navigate("/profile"); // Navigate to '/profile' route
  };

  useEffect(() => {
    const storedMenteeData = localStorage.getItem("menteeData");
    if (storedMenteeData) {
      try {
        const parsedData = JSON.parse(storedMenteeData);
        let matchedMentee = null;

        if (Array.isArray(parsedData)) {
          matchedMentee = parsedData.find(
            (mentee) => mentee.id.toString() === id
          );
        } else if (typeof parsedData === "object" && parsedData !== null) {
          if (parsedData.id.toString() === id) {
            matchedMentee = parsedData;
          }
        }

        if (matchedMentee) {
          setMenteeData(matchedMentee);
        } else {
          <ErrorToast message="No matching mentee found."/>
        }
      } catch (error) {
        <ErrorToast message={`Error fetching mentee, ${error}`}/>
      }
    } else {
      <ErrorToast message="No mentee data found."/>
    }
  }, [id]);

  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"}>
        <Typography sx={{ color: "#595A5F", fontSize: "12px", fontFamily: 'Be Vietnam Pro' }}>
          Started
        </Typography>
        <Typography sx={{ color: "#595A5F", fontSize: "12px", ml: 1, fontFamily: 'Be Vietnam Pro' }}>
          {formatDate(menteeData?.timestamp)}
        </Typography>
      </Box>
      <Typography
        sx={{ color: "#202124", fontSize: "24px", fontWeight: 500, mb: 1, fontFamily: 'Be Vietnam Pro' }}
      >
        {menteeData?.name}
      </Typography>
      <Typography sx={{ color: "#595A5F", fontSize: "12px", fontFamily: 'Be Vietnam Pro' }}>
        {menteeData?.description}
      </Typography>
      <Box display={"flex"} mt={4} justifyContent={"space-between"}>
        <Typography
          sx={{
            fontSize: "10px",
            color: "#868686",
            fontFamily: 'Be Vietnam Pro'
          }}
        >
          {menteeData?.progress}% completed
        </Typography>
        <Typography
          sx={{
            fontSize: "10px",
            color: "#868686",
            fontFamily: 'Be Vietnam Pro'
          }}
        >
          {`${menteeData?.is_completed ? "Completed" : "In Progress"}`}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <LinearProgress
          variant="determinate"
          value={menteeData?.progress}
          sx={{
            flex: 1,
            borderRadius: "5px",
            width: "100%",
            height: "8px",
            mt: 1,
          }}
        />
      </Box>
      <Card sx={{ mt: 4, height: "120px" }}>
        <CardContent sx={{ padding: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box display={"flex"}>
              <UserAvatar
                avatar={userProfile?.avatar}
                fullName={userProfile?.full_name}
              />
              <Box display={"flex"} flexDirection={"column"} ml={1}>
                <Typography sx={{ color: "#0172A6", fontSize: "16px" , fontFamily: 'Be Vietnam Pro'}}>
                  {userProfile?.full_name?.trim() || "N/A"}
                </Typography>
                <Typography sx={{ color: "#595A5F", fontSize: "12px", fontFamily: 'Be Vietnam Pro' }}>
                  {`${userProfile?.state}, ${userProfile?.country}`}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"end"} gap={1}>
              <VideocamOutlinedIcon
                sx={{ color: "#595A5F", width: "24px", height: "24px" }}
              />
              <FolderOpenOutlinedIcon
                sx={{ color: "#595A5F", width: "24px", height: "24px" }}
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"end"} mt={2}>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#DDF4FF",
                textTransform: "capitalize",
                height: "25px",
                fontFamily: 'Be Vietnam Pro'
              }}
              size="small"
              onClick={handleViewButtonClick}
            >
              View Profile
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default WorkspaceSidebarNew;

import MultipleChoiceQuestion from "components/QuestionTypes/MultipleChoiceQuestion";
import SubjectiveQuestion from "components/QuestionTypes/SubjectiveQuestion";
import ProgrammingQuestion from "components/QuestionTypes/ProgrammingQuestion";

export const questionTypeMap = {
  p: {
    name: "Programming Question",
    component: ProgrammingQuestion,
  },
  s: {
    name: "Subjective Question",
    component: SubjectiveQuestion,
  },
  o: {
    name: "Multiple Choice Question",
    component: MultipleChoiceQuestion,
  },
};

export const difficultyMap = {
  E: "Easy",
  M: "Medium",
  H: "Hard",
};

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./NavigationBar.css";

const NavigationBar = ({
  currentQuestionIndex,
  totalQuestions,
  handlePrevious,
  handleNext,
}) => {
  return (
    <Box className="nav-container">
      {/* <div className="underline"> */}
      <Button
        variant="contained"
        onClick={handlePrevious}
        disabled={currentQuestionIndex === 0}
      >
        <ArrowBackIcon /> Previous
      </Button>
      <Typography variant="h6">
        {currentQuestionIndex + 1} / {totalQuestions}
      </Typography>
      <Button
        variant="contained"
        onClick={handleNext}
        disabled={currentQuestionIndex === totalQuestions - 1}
      >
        Next
        <ArrowForwardIcon />
      </Button>
      {/* </div> */}
    </Box>
  );
};

export default NavigationBar;

import React from "react";
import { Link } from "react-router-dom";
import "./login.css";
import GoogleAuth from "./components/GoogleAuth";
import edgentLogo from "../../assets/svgImages/edgentLogo.svg";

export default function Login() {
  return (
    <div className="Auth-form-container">
      <form className="Auth-form">
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">
            <Link to="/" className="logo d-flex justify-content-center align-items-baseline gap-1">
              <img src={edgentLogo} alt="Logo" className="mr-1" />
            </Link>
          </h3>
          <div className="w-100" style={{ height: "50px" }}>
            <GoogleAuth />
          </div>
        </div>
      </form>
    </div>
  );
}

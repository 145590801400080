import React from "react";
import { Drawer, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomDrawer = ({ open, onClose, children, title }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        ".MuiPaper-root": {
          width: "90%",
        },
      }}
    >
      <Box p={2}>
        <Box display="flex" justifyContent="flex-end">
          <Box
            width={"100%"}
            display={"flex"}
            position={"absolute"}
            top={0}
            backgroundColor="#0181BB"
            right={0}
          >
            <Box width={"100%"} height={"64px"} display={'flex'} alignItems={'center'} pl={2} color={'white'} fontFamily='Be Vietnam Pro'>{title}</Box>
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ color: "white" }} />
            </IconButton>
          </Box>
        </Box>
        {children}
      </Box>
    </Drawer>
  );
};

export default CustomDrawer;

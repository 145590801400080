import React, { createContext, useState, useEffect, useContext } from "react";
import api from "../../api";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Initialize data as an empty object
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await api.get("user-profile/");
        setData(response?.data?.data || {}); // Default to an empty object
      } catch (err) {
        setError(err);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <UserContext.Provider
      value={{
        data,
        setData,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
};

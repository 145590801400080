import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Typography, Grid, Modal } from "@mui/material";
import "./component.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../aws-config";
import Loader from "../../components/Loader";
import ErrorToast from "components/Toaster/ErrorToast";
import api from '../../api'
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Calendar() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dateLoading, setDateLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [response, setResponse] = useState("");
  const onboardingData = useSelector((state) => state.onboarding.data);
  const isFormFilled = onboardingData ? onboardingData.form_filled : false;
  const isCallScheduled = onboardingData ? onboardingData.call_scheduled : false;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('workspace/date-availability/?step=phase-1');
        setData(response?.data);
      } catch (error) {
        <ErrorToast message={error.toString()} />
        setError(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [isFormFilled]);
  

  const handleTimeCardClick = (selectedDate, selectedTime) => {
    const postUrl = 'workspace/schedule-meeting/';
  
    const requestBody = {
      "selected-date": selectedDate,
      "selected-time": selectedTime,
    };
  
    setDateLoading(true);
  
    api
      .post(postUrl, requestBody)
      .then((response) => {
        setShowModal(true);
        setResponse(response?.data);
  
        // Reload the page after successful scheduling
        window.location.reload();
      })
      .catch((error) => {
        <ErrorToast message="Failed to Schedule Meet, please try again." />;
      })
      .finally(() => {
        setDateLoading(false);
      });
  };
  
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="w-100 w-xs-85 text-center m-4">
        <h3 className="py-4">Select Your Time Slot</h3>
        {(loading || dateLoading) && <Loader />}
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {loading ? (
            <Loader />
          ) : error ? (
            <p>Error: {error?.message}</p>
          ) : (
            data.map((dateItem, dateIndex) => (
              <Grid container item key={dateIndex} justifyContent="center">
                <Grid item xs={12} sm={6} md={3}>
                  <Card className="m-2">
                    <CardContent>
                      <Typography variant="h6">
                        {dateItem["available-date"]}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={9}>
                  <div className="d-flex justify-content-center flex-wrap">
                    {dateItem["available-time"].map((time, timeIndex) => (
                      <Card
                        key={timeIndex}
                        className="m-2"
                        onClick={() =>
                          handleTimeCardClick(dateItem["available-date"], time)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <CardContent>
                          <Typography>{time}</Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          disableEscapeKeyDown
          disableBackdropClick
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography variant="h5" mb={2}>
              Meeting Scheduled
            </Typography>
            <Typography variant="h6">
              Your Meeting is scheduled. Please accept the calendar invite in your
              email.
            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default Calendar;

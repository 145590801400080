import React from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Link,
  Tooltip,
} from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import logo from "../assets/homepage/edgentLogo.svg";

const Footer = () => {
  const handleOpenNewTab = (path) => {
    const newTab = window.open(`${window.location.origin}${path}`, "_blank");
    newTab?.focus();
  };

  const linkStyles = {
    fontFamily: "Be Vietnam Pro",
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    color: "inherit",
    textDecoration: "none",
  };

  return (
    <Box component="footer" sx={{ py: 6, mt: 4, backgroundColor: "#f9f9f9" }}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {/* Left Section: About Edgent */}
          <Grid item xs={12} md={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <img
                src={logo}
                alt="Edgent Logo"
                style={{ height: "40px", marginRight: "10px" }}
                loading="lazy"
              />
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "#202124",
                fontSize: "16px",
                width: "400px",
                fontFamily: "Be Vietnam Pro",
              }}
            >
              About Edgent: Edgent is a 1:1 Mentorship focused edtech company
              with a vision to help individuals in career success.
            </Typography>
          </Grid>

          {/* Center Section: Quick Links */}
          <Grid item xs={12} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 600, fontFamily: "Be Vietnam Pro" }}
            >
              Quick Links
            </Typography>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {[
                { text: "Home", path: "/" },
                { text: "Edgent Case Study", path: "/case-studies" },
                { text: "Edgent Blogs", path: "/blogs" },
                { text: "Edgent Startups", path: "/startups" },
              ].map((item) => (
                <li key={item.text} style={{ paddingBottom: "8px" }}>
                  <Tooltip title={`Open ${item.text}`}>
                    <span
                      onClick={() => handleOpenNewTab(item.path)}
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "inherit",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      {item.text}
                    </span>
                  </Tooltip>
                </li>
              ))}
            </ul>
          </Grid>

          {/* Right Section: Contact Us */}
          <Grid item xs={12} md={3}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 600, fontFamily: "Be Vietnam Pro" }}
            >
              Contact Us
            </Typography>
            <Box>
              <Link
                href="mailto:core@edgent.ai"
                underline="none"
                sx={linkStyles}
              >
                <EmailOutlinedIcon style={{ marginRight: "8px" }} />
                core@edgent.ai
              </Link>
              <Link href="tel:+918299463677" underline="none" sx={linkStyles}>
                <PhoneOutlinedIcon style={{ marginRight: "8px" }} />
                +91 8299463676
              </Link>
              <Link
                href="https://wa.me/918299463677"
                underline="none"
                target="_blank"
                sx={linkStyles}
              >
                <WhatsAppIcon style={{ marginRight: "8px" }} />
                WhatsApp Us
              </Link>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Bottom Section */}
        <Box
          mt={6}
          textAlign="center"
          sx={{ borderTop: "1px solid #D5D5D5", fontFamily: "Be Vietnam Pro" }}
          pt={3}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontFamily: "Be Vietnam Pro" }}
          >
            © {new Date().getFullYear()}. Edgent. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../AuthContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../aws-config";
import Loader from "../../../components/Loader";
import ErrorToast from "components/Toaster/ErrorToast";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve();
    };
    script.onerror = (err) => {
      reject(err);
    };
    document.body.appendChild(script);
  });

const GoogleAuth = () => {
  const { updateGoogleResponse } = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state
  const googleButton = useRef(null);

  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    if (accessToken) {
      fetch(`${baseURL}workspace/onboarding-data/`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUserData(data);
          localStorage.setItem("onboardingData", JSON.stringify(data));
          navigateAfterResponseWithRetry();
        })
        .catch((error) => {
          <ErrorToast message={`Failed to login:, ${error}`}/>;
        })
        .finally(() => {
          setLoading(false); // Stop loading after fetching data
        });
    } else {
      setLoading(false); // Stop loading if no access token
    }
  }, [accessToken]);

  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    const id =
      "444450736740-tiiqook4ob3sjou8f2a730umrv569ehn.apps.googleusercontent.com";

    loadScript(src)
      .then(() => {
        /*global google*/
        if (google && google?.accounts && google?.accounts.id) {
          google.accounts.id.initialize({
            client_id: id,
            callback: handleCredentialResponse,
          });
          if (googleButton.current) {
            google.accounts.id.renderButton(googleButton.current, {
              theme: "outline",
              size: "large",
            });
          } else {
            <ErrorToast message={`Something went wrong.`}/>;
          }
        } else {
          <ErrorToast message={`Something went wrong.`}/>;
        }
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);

  function handleCredentialResponse(response) {
    setLoading(true); // Start loading on credential response

    if (response.credential) {
      var data = { auth_token: response.credential };
      fetch(`${baseURL}google/`, {
        method: "post",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          updateGoogleResponse(res);
          navigateAfterResponseWithRetry();
        })
        .catch((error) => {
          <ErrorToast message={`Something went wrong.`}/>;
        })
        .finally(() => {
          setLoading(false); // Stop loading after handling response
        });
    }
  }

  const MAX_RETRIES = 1; // Maximum number of retries
  let retryCounter = 0;

  function navigateAfterResponseWithRetry() {
    retryNavigation();
  }

  function retryNavigation() {
    const retryInterval = 1000; // Retry interval in milliseconds
    if (retryCounter < MAX_RETRIES) {
      navigateAfterResponse();
      retryCounter++;
      setTimeout(retryNavigation, retryInterval);
    } else {
      console.error("Maximum retries reached. Navigation failed.");
    }
  }

  useEffect(() => {
    navigateAfterResponse();
  }, [userData, navigate, toast, accessToken]);

  function navigateAfterResponse() {
    if (accessToken && userData && userData.form_filled === false) {
      navigate("/registration-form");
    } else if (accessToken && userData) {
      navigate("/calendar-meeting"); // Navigate to CalendarMeeting
    }
  }

  return (
    <div id="google-login-btn">
      {loading && <Loader />} {/* Show Loader when loading */}
      <div ref={googleButton} id="google-ref">
        <ToastContainer />
      </div>
    </div>
  );
};

export default GoogleAuth;

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import axios from "axios";

let dbPromise = null;

const initializeFirebase = async (firebaseConfig) => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const analytics = getAnalytics(app);
  return db;
};

export const fetchFirebaseConfig = async () => {
  const accessToken = localStorage.getItem("access_token");
  if (!accessToken) {
    throw new Error("Access token is missing");
  }
  try {
    const response = await axios.get(
      "https://backend.edgent.ai/chats/credentials/",
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.client_credentials;
  } catch (err) {
    throw new Error("Error fetching data: " + err.message);
  }
};

export const getDb = async () => {
  if (!dbPromise) {
    const firebaseConfig = await fetchFirebaseConfig();
    dbPromise = initializeFirebase(firebaseConfig);
  }
  return dbPromise;
};

import React, { useState } from "react";
import { Box, Typography, Grid, Button, CircularProgress } from "@mui/material";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import Quill's snow theme CSS
import { baseURL } from "../aws-config";
import ErrorToast from "components/Toaster/ErrorToast";
import api from '../api'

const Feedback = ({ course }) => {
  const [feedbackContent, setFeedbackContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = localStorage.getItem("access_token");

  const handleSubmitFeedback = async () => {
    if (isLoading) return;
    setIsLoading(true);
  
    const apiUrl = `course/${course?.id}/feedback/`;
    const requestBody = {
      feedback: feedbackContent,
    };
  
    try {
      const response = await api.post(apiUrl, requestBody);
      if (!response.ok) {
        <ErrorToast message="Failed to submit feedback" />;
      }
      setFeedbackContent("");
    } catch (error) {
      <ErrorToast message={`Failed to submit feedback: ${error}`} />;
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", width: "100%", gap: "8px", mt: 3 }}>
            <Typography
              sx={{
                border: "1px solid #D5D5D5",
                borderRadius: "12px",
                fontSize: "16px",
                padding: "12px",
                backgroundColor: "#E5F1F6",
                wordWrap: "break-word",
              }}
              component="div"
            >
              {course?.feedback ? (
                <div
                  dangerouslySetInnerHTML={{ __html: course.feedback }}
                />
              ) : (
                "Write your feedback about the Mentee."
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            mt={3}
            position={"absolute"}
            bottom={50}
            left={0}
            width={"100%"}
            p={2}
          >
            <Box
              display={"flex"}
              backgroundColor="#DCDEDE"
              justifyContent={"space-between"}
              alignItems={"center"}
              p={1}
            >
              <Typography sx={{fontFamily: 'Be Vietnam Pro'}}>Write your answer here</Typography>
              <Button
                onClick={handleSubmitFeedback}
                sx={{
                  backgroundColor: "#0181BB",
                  color: "white",
                  textTransform: "capitalize",
                  height: "35px",
                  fontFamily: 'Be Vietnam Pro',
                  "&:hover": {
                    backgroundColor: "#0172A6",
            fontFamily: 'Be Vietnam Pro',
                  },
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
            <ReactQuill
              theme="snow"
              placeholder="Write your answer here..."
              style={{ height: "200px" , fontFamily: 'Be Vietnam Pro'}}
              value={feedbackContent}
              onChange={setFeedbackContent}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Feedback;

import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { CardBody } from "reactstrap";
import Slideshow from "./SlideShow";
import ModalComponent from "../components/RegistrationForm";
import { Link } from "react-router-dom";

function MainContentCard({ staticPage }) {
  const [showModal, setShowModal] = useState(false);
  const onboardingData = localStorage.getItem("onboardingData");
  const parsedData = onboardingData ? JSON.parse(onboardingData) : null;
  const isFormFilled = parsedData?.form_filled || false;
  const isCallScheduled = onboardingData ? onboardingData.call_scheduled : false;

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="mt-4 d-flex justify-content-center mb-4">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ width: "100%", textAlign: "center" }}
      >
        <div className="px-4 ml-4" style={{ width: "95%" }}>
          <Card
            className="rounded mx-auto"
            style={{
              backgroundColor: "rgba(231, 248, 253, 0.4)",
              borderRadius: "10px",
              border: "none",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardBody className="w-100 p-4 text-center">
              <div
                className="w-100 text-lg text-md" // Responsive utility classes
                style={{
                  fontSize: "3rem",
                  fontWeight: 600,
                  textAlign: "center",
                  color: "#21252E",
                }}
              >
                Start Your Journey To Your Dream Job Today!
              </div>
              <div className="w-100 d-flex flex-column justify-content-start my-2">
                {staticPage ? (
                  <Link to="/about-us" className="mb-2">
                    <Button variant="primary" className="text-lg">
                      Coming Soon
                    </Button>
                  </Link>
                ) : (
                  <>
                    {isFormFilled ? (
                      // If form_filled is true, redirect to '/call-scheduled'
                      <Link to="/call-scheduled" className="mb-2">
                        <Button variant="outline-primary" className="text-lg">
                          Free Career Goal Discussion
                        </Button>
                      </Link>
                    ) : (
                      // If form_filled is false, redirect to '/registration-form'
                      <Link to="/registration-form" className="mb-2">
                        <Button variant="outline-primary" className="text-lg">
                          Free Career Goal Discussion
                        </Button>
                      </Link>
                    )}
                  </>
                )}
                {/* <ModalComponent
                showModal={showModal}
                handleCloseModal={handleCloseModal}
              /> */}
                {/* <Button
                  variant="outline-primary"
                  className="text-lg" // Responsive utility class
                >
                  Get Started
                </Button> */}
              </div>
            </CardBody>
          </Card>
        </div>
        {/* <div className="w-100 px-4" style={{ height: "450px" }}>
          <Slideshow />
        </div> */}
      </div>
    </div>
  );
}

export default MainContentCard;

import React, { useState, useEffect, useRef } from "react";
import { collection, query, orderBy, onSnapshot, limit, startAfter, getDocs } from "firebase/firestore";
import { getDb } from "../firebase/auth";
import Message from "./Message";
import { useUser } from "../context/UserContext";

function MessageSpace({ chat }) {
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [db, setDb] = useState(null);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const { role, mentorId, mentiiId } = useUser();
  const currentUserId = mentorId || mentiiId;
  const messageSpaceRef = useRef(null);
  const initialFetchLimit = 20;

  useEffect(() => {
    const initialize = async () => {
      try {
        const dbInstance = await getDb();
        setDb(dbInstance);

        let firstId, secondId;
        if (role === "mentor") {
          firstId = mentorId;
          secondId = chat.id;
        } else {
          firstId = chat.id;
          secondId = mentiiId;
        }

        if (firstId && secondId) {
          const collectionName = `${firstId}${secondId}`;
          const q = query(
            collection(dbInstance, collectionName),
            orderBy("timeStamp", "desc"),
            limit(initialFetchLimit)
          );

          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const fetchedMessages = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })).reverse(); // Reverse to display from oldest to newest

            setMessages(fetchedMessages);
            setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setHasMore(querySnapshot.size === initialFetchLimit);
            // Scroll to bottom after initial load
            setTimeout(scrollToBottom, 0);
          });

          return () => unsubscribe();
        }
      } catch (err) {
        setError(err);
      }
    };

    initialize();
  }, [role, mentorId, mentiiId, chat.id]);

  const fetchMoreMessages = async () => {
    if (!db || !lastDoc || loading) return;

    setLoading(true);

    const scrollHeightBeforeFetch = messageSpaceRef.current.scrollHeight;
    const scrollTopBeforeFetch = messageSpaceRef.current.scrollTop;

    try {
      let firstId, secondId;
      if (role === "mentor") {
        firstId = mentorId;
        secondId = chat.id;
      } else {
        firstId = chat.id;
        secondId = mentiiId;
      }

      const collectionName = `${firstId}${secondId}`;
      const q = query(
        collection(db, collectionName),
        orderBy("timeStamp", "desc"),
        startAfter(lastDoc),
        limit(initialFetchLimit)
      );

      const querySnapshot = await getDocs(q);
      const fetchedMessages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).reverse();

      setMessages((prevMessages) => [...fetchedMessages, ...prevMessages]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.size === initialFetchLimit);

      // Maintain scroll position relative to new messages
      setTimeout(() => {
        const scrollHeightAfterFetch = messageSpaceRef.current.scrollHeight;
        messageSpaceRef.current.scrollTop = scrollTopBeforeFetch + (scrollHeightAfterFetch - scrollHeightBeforeFetch);
      }, 0);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (messageSpaceRef.current.scrollTop === 0 && hasMore && !loading) {
      fetchMoreMessages();
    }
  };

  const scrollToBottom = () => {
    if (messageSpaceRef.current) {
      messageSpaceRef.current.scrollTop = messageSpaceRef.current.scrollHeight;
    }
  };

  return (
    <div ref={messageSpaceRef} className="message-space" onScroll={handleScroll}>
      {/* {loading && (
        <div className="loader">Loading...</div>
      )} */}
      {messages.map((msg) => (
        <div
          key={msg.id}
          className={`message ${
            msg.senderId === currentUserId ? "message-right" : "message-left"
          }`}
        >
          <div
            className={`message-content ${
              msg.senderId === currentUserId
                ? "message-content-right"
                : "message-content-left"
            }`}
          >
            <Message
              message={msg.text}
              isSender={msg.senderId === currentUserId}
              timeStamp={msg.timeStamp}
            />
          </div>
        </div>
      ))}
      {error && <p style={{ color: "red" }}>Error: {error.message}</p>}
    </div>
  );
}

export default MessageSpace;

import React from 'react';
import { Modal, Box, Typography, IconButton, Avatar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Custom Next Arrow
const NextArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translate(50%, -50%)',
      zIndex: 1,
      color: '#0181BB',
      bgcolor: 'white',
      '&:hover': { bgcolor: '#f0f0f0' },
      boxShadow: 1,
    }}
  >
    <ArrowForwardIcon />
  </IconButton>
);

// Custom Prev Arrow
const PrevArrow = ({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      left: '10px',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
      color: '#0181BB',
      bgcolor: 'white',
      '&:hover': { bgcolor: '#f0f0f0' },
      boxShadow: 1,
    }}
  >
    <ArrowBackIcon />
  </IconButton>
);

const ViewProgramPopup = ({ title, items, open, onClose }) => {
  // Slick carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 3,
          p: 4,
          pb:10,
          fontFamily: 'Be Vietnam Pro',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h5" fontWeight="bold" color="#0181BB" fontFamily="Be Vietnam Pro">
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Carousel with Slider */}
        <Slider {...settings}>
          {items.map((item, index) => (
            <Box key={index} sx={{ p: 2, textAlign: 'center' }}>
              {/* Icon or Image at the top */}
              <Box sx={{ mb: 2 }}>
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    mx: 'auto',
                    mb: 2,
                    fontSize: '20px',
                    backgroundColor: '#0181BB',
                    boxShadow: 3,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon}
                </Avatar>
              </Box>
              
              {/* Content */}
              <Typography variant="h6" fontWeight="bold" color="#0181BB" fontFamily="Be Vietnam Pro">
                {item.title}
              </Typography>
              <Typography variant="body1" fontFamily="Be Vietnam Pro" sx={{ mt: 1, mb: 2, color: 'text.secondary' }}>
                {item.description}
              </Typography>
            </Box>
          ))}
        </Slider>
      </Box>
    </Modal>
  );
};

export default ViewProgramPopup;

import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [role, setRole] = useState(null);
  const [mentorId, setMentorId] = useState(null);
  const [mentiiId, setMentiiId] = useState(null);
  const [mentorData, setMentorData] = useState([]);
  const [mentiiData, setMentiiData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        setError(new Error("Access token is missing"));
        return;
      }

      try {
        const response = await axios.get(
          "https://backend.edgent.ai/user-profile/",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const userRole = response.data.data.role;
        setRole(userRole);
        if (userRole === "mentor") {
          setMentorId(String(response.data.data.id));
        } else {
          setMentiiId(String(response.data.data.id));
        }
      } catch (err) {
        setError(err);
      }
    };

    fetchUserProfile();
  }, []);
  useEffect(() => {
    if (!role) return;

    const fetchChats = async () => {
      try {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
          setError(new Error("Access token is missing"));
          return;
        }

        let response;
        if (role === "mentor") {
          response = await axios.get(
            "https://backend.edgent.ai/chats/mentees/",
            { headers: { Authorization: `Bearer ${accessToken}` } }
          );
          setMentiiData(response.data || []);
        } else {
          response = await axios.get(
            "https://backend.edgent.ai/chats/mentors/",
            { headers: { Authorization: `Bearer ${accessToken}` } }
          );
          setMentorData(response.data || []);
        }
      } catch (err) {
        setError(err);
      }
    };

    fetchChats();
  }, [role]);

  return (
    <UserContext.Provider
      value={{
        role,
        setRole,
        mentorId,
        mentiiId,
        mentorData,
        mentiiData,
        error,
        setMentiiId,
        setMentorId,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

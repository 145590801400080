import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SuccessModal = ({ open, onClose, message }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="success-dialog-title"
      aria-describedby="success-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        id="success-dialog-title"
        sx={{
          textAlign: "center",
          color: "#0181BB",
          fontWeight: "bold",
          fontSize: "1.25rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CheckCircleIcon sx={{ fontSize: 60, color: "#0181BB", mb: 2 }} />
        {message || 'Success!'}
      </DialogTitle>

      <DialogActions
        sx={{
          justifyContent: "center",
          padding: "1rem",
        }}
      ></DialogActions>
    </Dialog>
  );
};

export default SuccessModal;

import React from "react";
import Playground from "components/CodeSpace/Playground/Playground";

const ProgrammingQuestion = () => {
  return (
    <div style={{ height: "83vh" }}>
      <Playground />
    </div>
  );
};

export default ProgrammingQuestion;

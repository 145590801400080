import { createSlice } from "@reduxjs/toolkit";

export const moduleSlice = createSlice({
  name: "module",
  initialState: {
    selectedModuleId: null,
  },
  reducers: {
    setSelectedModuleId: (state, action) => {
      state.selectedModuleId = action.payload;
    },
  },
});

export const { setSelectedModuleId } = moduleSlice.actions;

export default moduleSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorToast from "components/Toaster/ErrorToast";
import api from "../api";

// MenteeCard Component to display individual mentee details
const MenteeCard = ({ mentee }) => {
  const {
    id,
    first_name,
    last_name,
    country,
    state,
    name,
    description,
    timestamp,
    progress,
  } = mentee;
  const navigate = useNavigate();

  const fullName = `${first_name} ${last_name}`.trim() || "N/A";
  const location = !state || !country ? "N/A" : `${state}, ${country}`.trim();
  const startDate = new Date(timestamp).toLocaleDateString();

  const handleViewButtonClick = () => {
    navigate(`/dashboard/workspace/${id}`);
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={6}>
      <Card
        sx={{
          borderRadius: "5px",
          border: "none",
          height: { xs: "auto", sm: "28vh" },
          minHeight: { xs: "auto", sm: "250px" },
          maxWidth: { xs: "100%", sm: "550px" },
          margin: "0 auto", // Center the card
          p: 2,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {mentee?.avatar ? (
                <Avatar
                  sx={{ width: 30, height: 30, marginBottom: 2, mt: 1 }}
                  src={mentee?.avatar}
                  alt={fullName}
                />
              ) : (
                <Avatar
                  sx={{
                    width: 30,
                    height: 30,
                    marginBottom: 2,
                    mt: 1,
                    backgroundColor: "#0181BB",
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  {fullName?.charAt(0)?.toUpperCase()}
                </Avatar>
              )}
              <Box display={"flex"} flexDirection={"column"} ml={1}>
                <Typography
                  sx={{
                    color: "#202124",
                    fontSize: { xs: "14px", sm: "16px" },
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  {fullName}
                </Typography>
                <Typography
                  sx={{
                    color: "#595A5F",
                    fontSize: { xs: "10px", sm: "12px" },
                    fontFamily: "Be Vietnam Pro",
                  }}
                >
                  {location ? location : "N/A"}
                </Typography>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              mt={{ xs: 2, sm: 0 }}
            >
              <Typography
                sx={{
                  color: "#595A5F",
                  fontSize: { xs: "10px", sm: "12px" },
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                Started
              </Typography>
              <Typography
                sx={{
                  color: "#595A5F",
                  fontSize: { xs: "10px", sm: "12px" },
                  fontFamily: "Be Vietnam Pro",
                }}
              >
                {startDate}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{
              color: "#202124",
              fontSize: { xs: "18px", sm: "24px" },
              fontWeight: 500,
              mb: 2,
              fontFamily: "Be Vietnam Pro",
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              marginRight: 1,
              fontSize: { xs: "8px", sm: "10px" },
              marginBottom: "-12px",
              color: "#595A5F",
              paddingTop: "16px",
              fontFamily: "Be Vietnam Pro",
            }}
          >
            {`${progress?.toFixed(0)}% completed`}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ flex: 1, mr: 4 }}
            />
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#DDF4FF",
                textTransform: "capitalize",
                borderRadius: "6px",
                fontFamily: "Be Vietnam Pro",
                fontSize: { xs: "12px", sm: "14px" },
                px: { xs: 1, sm: 2 },
              }}
              size="small"
              onClick={handleViewButtonClick}
            >
              View
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

// MenteeCardList Component to fetch and display list of mentees
const MenteeCardList = () => {
  const [menteeData, setMenteeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const accessToken = localStorage.getItem("access_token");
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.data?.role;

  const fetchMenteeData = async () => {
    try {
      const response = await api.get("dashboard/");
      setMenteeData(response?.data);
      localStorage.setItem("menteeData", JSON.stringify(response?.data));
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
        <ErrorToast message="Your session has expired. Please log in again." />;
      } else {
        <ErrorToast message={"Failed to fetch mentee data"} />;
      }
      setError("Failed to fetch mentee data");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userRole === "mentor") {
      fetchMenteeData();
    }
  }, [accessToken]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const menteeArray = Array.isArray(menteeData) ? menteeData : [menteeData];

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: "Be Vietnam Pro",
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  if (!menteeData || menteeData?.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
          fontFamily: "Be Vietnam Pro",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Be Vietnam Pro",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          No Data Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ px: { xs: 2, sm: 5, md: 10 }, py: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start", // Align to the left
          mb: 2,
          pl: { xs: 0, sm: 0, lg: 3.5 }, 
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            sx={{
              width: "100px",
              height: "30px",
              backgroundColor: "#D9D9D9",
              color: "#595A5F",
              textTransform: "capitalize",
              fontFamily: "Be Vietnam Pro",
            }}
            size="small"
          >
            Ongoing
          </Button>
          <Button
            sx={{
              width: "100px",
              height: "30px",
              backgroundColor: "#D9D9D9",
              color: "#595A5F",
              textTransform: "capitalize",
              fontFamily: "Be Vietnam Pro",
            }}
            disabled
            size="small"
          >
            Completed
          </Button>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {menteeArray?.map((mentee) => (
          <MenteeCard key={mentee?.id} mentee={mentee} />
        ))}
      </Grid>
    </Box>
  );
};

export default MenteeCardList;

import React from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const CallToAction = () => {
  const theme = useTheme();
  const accessToken = localStorage.getItem("access_token");
  const redirectTo = accessToken ? "/calendar-meeting" : "/login";

  return (
    <Box
      textAlign="center"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      height={"350px"}
    >
      <Typography
        gutterBottom
        sx={{
          color: "white",
          fontWeight: 600,
          fontSize: {
            xs: "36px", // Adjust font size for extra small screens
            sm: "42px", // Adjust font size for small screens
            md: "52px", // Default font size for medium screens and above
          },
          width: "60%",
          fontFamily: "Be Vietnam Pro",
        }}
      >
        Get Started with your 1:1 Mentorship
      </Typography>
      <Link to={redirectTo} style={{ textDecoration: "none" }} className="mb-2">
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: "142px",
            fontSize: {
              xs: "14px", // Adjust font size for extra small screens
              sm: "16px", // Adjust font size for small screens
              md: "18px", // Default font size for medium screens and above
            },
            borderRadius: "50px",
            background: "linear-gradient(90deg, #0181BB 0%, #086690 100%)",
            color: "white",
            textTransform: "capitalize",
            fontFamily: "Be Vietnam Pro",
            marginTop: "16px",
          }}
        >
          Join Now
        </Button>
      </Link>
    </Box>
  );
};

export default CallToAction;

import React from "react";

function Message({ message, isSender, timeStamp }) {
  const formatAMPM = (timestamp) => {
    const date = new Date(timestamp?.seconds * 1000);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  };

  return (
    <div className={`message ${isSender ? "sender" : "receiver"}`}>
      <div>{message}</div>
      <div className="timestamp">{formatAMPM(timeStamp)}</div>
    </div>
  );
}

export default Message;

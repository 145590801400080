import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Tooltip , Button} from "@mui/material";
import SessionIcon from "../assets/svgImages/sessionIcon.svg";
import FolderIcon from "../assets/svgImages/folderIcon.svg";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import CustomDrawer from "./CustomDrawer";
import Questionnaire from "./Questionnaire";
import Feedback from "./Feedback";
import dayjs from "dayjs";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ErrorToast from "components/Toaster/ErrorToast";
import ScheduleMeetingModal from "./ScheduleMeetingModal";

function WrapUpSessionData({ course }) {
  const [isQuestionnaireDrawerOpen, setIsQuestionnaireDrawerOpen] =
    useState(false);
  const [isFeedbackDrawerOpen, setIsFeedbackDrawerOpen] = useState(false);
  const { id } = useParams(); // Extract the ID from the URL
  const [openModal, setOpenModal] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [menteeData, setMenteeData] = useState(null);

  const toggleQuestionnaireDrawer = () => {
    setIsQuestionnaireDrawerOpen(!isQuestionnaireDrawerOpen);
  };

  const toggleFeedbackDrawer = () => {
    setIsFeedbackDrawerOpen(!isFeedbackDrawerOpen);
  };
  const { wrapup_meeting_data } = course || {};
  const { start_time, meeting_link ,end_time} = wrapup_meeting_data || {};

  // Format the start_time to a readable string
  const formattedTime =
    start_time && end_time
      ? format(new Date(start_time), "EEEE, dd MMM h:mm a") +
        " - " +
        format(new Date(end_time), "h:mm a")
      : "";
  const isMeetingScheduled = Boolean(meeting_link);



  function generateTimeSlots() {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return timeSlots;
  }

  const timeSlots = generateTimeSlots();

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleStartTimeChange = (time) => {
    setStartTime(time);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function generateTimeSlots() {
    const timeSlots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return timeSlots;
  }

  useEffect(() => {
    const storedMenteeData = localStorage.getItem("menteeData");
    if (storedMenteeData) {
      try {
        const parsedData = JSON.parse(storedMenteeData);
        let matchedMentee = null;

        if (Array.isArray(parsedData)) {
          matchedMentee = parsedData.find(
            (mentee) => mentee.id.toString() === id
          );
        } else if (typeof parsedData === "object" && parsedData !== null) {
          if (parsedData.id.toString() === id) {
            matchedMentee = parsedData;
          }
        }

        if (matchedMentee) {
          setMenteeData(matchedMentee);
        } else {
          <ErrorToast message="No matching mentee found for the given ID." />;
        }
      } catch (error) {
        <ErrorToast message={error} />;
      }
    } else {
      <ErrorToast message="No mentee data found." />;
    }
  }, [id]);

  return (
    <>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            backgroundColor="#E5F1F6"
            p={1}
            mt={2}
          >
            <Box display="flex" alignItems="center">
              <img src={SessionIcon} alt="Session" style={{ marginRight: 8 }} />

              <Tooltip title={formattedTime} arrow>
                <Typography
                  sx={{
                    color: "#202124",
                    fontSize: "14px",
                    fontFamily: "Be Vietnam Pro",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(meeting_link, "_blank")}
                >
                  Schedule 1:1
                </Typography>
              </Tooltip>

              {isMeetingScheduled && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: "12px",
                    textTransform: "none",
                    borderRadius: "6px",
                    ml: 2,
                    height: "24px",
                    mt: "2px",
                    backgroundColor: "#0172A6", // Button color
                    "&:hover": {
                      backgroundColor: "#015d8d", // Darker shade for hover effect
                      boxShadow: "none", // Remove default shadow
                    },
                    "&:active": {
                      backgroundColor: "#014d74", // Even darker shade for active state
                    },
                  }}
                  onClick={() => window.open(meeting_link, "_blank")}
                >
                  Join Now
                </Button>
              )}
            </Box>

            <Tooltip title="Reschedule Meeting" arrow>
              <ModeEditIcon
                sx={{ fontSize: "18px", cursor: "pointer" }}
                onClick={handleModalOpen}
              />
            </Tooltip>
          </Box>
        </Grid> */}
        <Grid item xs={12} mt={2}>
          <Box
            sx={{
              backgroundColor: "#E5F1F6",
              width: "100%",
              display: "flex",
              alignItems: "center",
              p: 1,
              cursor: "pointer",
            }}
            onClick={() => toggleQuestionnaireDrawer()}
          >
            <StarBorderRoundedIcon
              sx={{ fontSize: "24px", color: "#0181BB", mr: "4px" }}
            />
            <Typography
              sx={{
                color: "#202124",
                fontSize: "14px",
                fontFamily: "Be Vietnam Pro",
                cursor: "pointer",
              }}
            >
              Ratings/Questionnaire
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundColor: "#E5F1F6",
              width: "100%",
              display: "flex",
              p: 1,
            }}
            onClick={() => toggleFeedbackDrawer()}
          >
            <img src={FolderIcon} alt="Folder" style={{ marginRight: 8 }} />
            <Typography
              sx={{
                color: "#202124",
                fontSize: "14px",
                fontFamily: "Be Vietnam Pro",
                cursor: "pointer",
              }}
            >
              Feedback
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <CustomDrawer
        open={isQuestionnaireDrawerOpen}
        onClose={() => setIsQuestionnaireDrawerOpen(false)}
        title={"Questionnaire"}
      >
        <Typography variant="h6" marginTop={"60px"}>
          <Questionnaire />
        </Typography>
      </CustomDrawer>
      <CustomDrawer
        open={isFeedbackDrawerOpen}
        onClose={() => setIsFeedbackDrawerOpen(false)}
        title={"Feedback"}
      >
        <Typography variant="h6" marginTop={"60px"}>
          <Feedback course={course} />
        </Typography>
      </CustomDrawer>
      <ScheduleMeetingModal
        open={openModal}
        onClose={handleModalClose}
        selectedDate={selectedDate}
        handleDateChange={handleDateChange}
        timeSlots={timeSlots}
        startTime={startTime}
        handleStartTimeChange={handleStartTimeChange}
        // handleSave={handleSave}
        menteeData={menteeData}
        type={2}
      />
    </>
  );
}

export default WrapUpSessionData;

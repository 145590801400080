import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  Link,
  Typography,
  Toolbar,
  Button,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeSlug from "rehype-slug";
import Loader from "../components/Loader";
import { useNavigate } from "react-router";
import edgentWhite from "../assets/homepage/edgentWhite.svg";

const HandBookTemplate = ({ toc }) => {
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState(toc[0]?.id || "");
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);
      const currentTopic = toc.find((item) => item.id === currentSection);
      if (currentTopic?.url) {
        // Fetch markdown content if URL exists
        const response = await fetch(currentTopic.url);
        const markdown = await response.text();
        setContent(markdown);
      } else {
        // Show "Coming Soon" if no URL
        setContent(`
          <div style="
            text-align: center;
            margin-top: 50px;
            font-size: 1.5rem;
            color: #555555;
            font-family: 'Be Vietnam Pro', sans-serif;
          ">
            🚧 Coming Soon 🚧
          </div>
        `);
      }
      setLoading(false);
    };

    fetchContent();
  }, [currentSection, toc]);

  const handleClick = (id) => {
    setCurrentSection(id);
  };

  const handleJoinNow = () => {
    if (!accessToken) {
      navigate("/login");
    } else {
      navigate("/calendar-meeting");
    }
  };

  return (
    <>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#0181BB", // Blue background color
          padding: "8px 16px", // Add padding for aesthetics
        }}
      >
        <Link to="/" style={{ textDecoration: "none" }}>
          <Box
            component="img"
            src={edgentWhite}
            alt="Edgent Logo"
            sx={{
              height: { lg: "60px", md: "60px", sm: "40px", xs: "40px" },
            }}
          />
        </Link>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "capitalize",
              backgroundColor: "white",
              color: "#0181BB",
              fontFamily: "Be Vietnam Pro",
              borderRadius: "50px",
              border: "1px solid #0181BB",
              "&:hover": {
                backgroundColor: "#E0F3FA",
                color: "#086690",
              },
            }}
            onClick={handleJoinNow}
          >
            Sign In
          </Button>
        </Box>
      </Toolbar>

      <Grid container>
        <Grid item xs={3}>
          <Box
            sx={{
              padding: "8px",
              backgroundColor: "#f9f9f9",
              height: "100vh",
              overflowY: "auto",
            }}
          >
            {/* <Typography
              variant="h6"
              sx={{ fontSize: "1rem", fontFamily: "Be Vietnam Pro" }}
            >
              Table of Contents
            </Typography> */}
            <List sx={{ paddingLeft: 0, fontFamily: "Be Vietnam Pro" }}>
              {toc.map((item) => (
                <ListItem
                  key={item.id}
                  sx={{
                    display: "block",
                    paddingLeft: "8px",
                    backgroundColor:
                      currentSection === item.id ? "#E0F3FA" : "transparent",
                    cursor: "pointer",
                    borderRadius: "4px",
                  }}
                  onClick={() => handleClick(item.id)}
                >
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      fontFamily: "Be Vietnam Pro",
                      fontWeight:
                        currentSection === item.id ? "bold" : "normal",
                      color: currentSection === item.id ? "#0181BB" : "#333333",
                    }}
                  >
                    {item.title}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>

        <Grid item xs={9}>
          <Box
            sx={{
              padding: "16px",
              maxHeight: "100vh",
              overflowY: "auto",
              paddingRight: "24px",
            }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Box
                sx={{
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  maxWidth: "100%",
                }}
              >
                {toc.find((item) => item.id === currentSection)?.url ? (
                  <ReactMarkdown
                    children={content}
                    rehypePlugins={[rehypeSlug]}
                  />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HandBookTemplate;

import { configureStore } from "@reduxjs/toolkit";
import countriesReducer from "../src/new-homepage/redux/countriesSlice";
import onboardingReducer from "../src/Homepage/onboardingReducer";
import userProfileReducer from "../src/new-homepage/redux/userProfileSlice";
import moduleReducer from "../src/new-homepage/redux/moduleSlice";

export const store = configureStore({
  reducer: {
    countries: countriesReducer,
    onboarding: onboardingReducer,
    userProfile: userProfileReducer,
    module: moduleReducer,
  },
});

import React, { useState, useEffect } from "react";
import { Typography, Button, Box, Divider } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import NavigationBar from "../NavigationBar/NavigationBar";
import axios from "axios";
import { baseURL } from "../../aws-config";
import "./SubjectiveQuestion.css";
import ReactMarkdown from "react-markdown";
import UserAvatar from "components/UserAvatar/UserAvatar";
import Toaster from "components/Toaster/Toaster";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import ErrorToast from "components/Toaster/ErrorToast";
import api from "../../api";

const toolbarOptions = [
  ["undo", "redo"],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["bold", "italic", "underline", "strike"],
  [{ align: [] }],
  ["blockquote", "code-block"],
  ["link", "image", "video"],
  ["clean"],
];

const modules = {
  toolbar: {
    container: toolbarOptions,
    handlers: {
      image: handleImageUpload,
    },
  },
  clipboard: {
    matchVisual: false,
  },
};

function handleImageUpload() {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();
  input.onchange = async () => {
    const file = input.files[0];
    if (file.size > 102400) {
      alert("Image size should not exceed 100KB");
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const quill = this.quill;
      const range = quill.getSelection();
      quill.insertEmbed(range.index, "image", e.target.result);
    };
    reader.readAsDataURL(file);
  };
}

const SubjectiveQuestion = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answer, setAnswer] = useState("");
  const [submittedAnswers, setSubmittedAnswers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [toaster, setToaster] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const userProfile = useSelector((state) => state.userProfile.data);
  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await api.get(`course/${selectedModuleId}/s/`);
        setQuestions(response?.data);
        setSubmittedAnswers(
          response?.data?.map((q) => q.submission_data?.[0]?.submission || "")
        );
        setLoading(false);
      } catch (error) {
        <ErrorToast message={error} />;
        setError(error);
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [selectedModuleId]);

  const handleAnswerChange = (value) => {
    if (value.length <= 2000) {
      setAnswer(value);
    } else {
      setToaster({
        open: true,
        message: "Character limit exceeded",
        severity: "error",
      });
    }
  };

  const handleSubmit = async () => {
    if (!answer.trim()) {
      setToaster({
        open: true,
        message: "Answer cannot be empty",
        severity: "error",
      });
      return Promise.resolve(); // Resolve immediately if answer is empty
    }
  
    const currentQuestion = questions[currentQuestionIndex];
    try {
      await api.post(`course/${selectedModuleId}/s/${currentQuestion?.id}/`, { submission: answer });
      
      const updatedAnswers = [...submittedAnswers];
      updatedAnswers[currentQuestionIndex] = answer;
      setSubmittedAnswers(updatedAnswers);
      setAnswer("");
      setToaster({
        open: true,
        message: "Response saved successfully",
        severity: "success",
      });
      return Promise.resolve(); // Resolve when answer is successfully submitted
    } catch (error) {
      if (error.response?.status === 401) {
        <ErrorToast message="Your session has expired. Please log in again." />;
      } else {
        setToaster({
          open: true,
          message: "Failed to save response",
          severity: "error",
        });
      }
      return Promise.reject(error); // Reject if there's an error
    }
  };
  

  const handleNext = async () => {
    // Submit answer if non-empty and wait for it to complete
    if (answer.trim()) {
      await handleSubmit();
    }
    
    // Move to the next question
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setAnswer(submittedAnswers[currentQuestionIndex + 1] || "");
  };
  

  const handlePrevious = () => {
    setAnswer(submittedAnswers[currentQuestionIndex - 1] || "");
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleCloseToaster = () => {
    setToaster({ ...toaster, open: false });
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        Error: {error?.message}
      </Typography>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div>
      <NavigationBar
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions.length}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
      />
      <Divider
        sx={{
          borderColor: "black",
          borderWidth: "1px",
          width: "100%",
          marginBottom: "16px",
        }}
      />
      <div className="paper">
        <Box display="flex" alignItems="center" mb={0}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            bgcolor="#1976d2"
            color="white"
            width={40}
            height={40}
            mr={4}
            mb={5}
          >
            <UserAvatar
              avatar={userProfile?.avatar}
              fullName={userProfile?.full_name}
            />
          </Box>
          <Box className="question-box">
            <ReactMarkdown>{currentQuestion?.description}</ReactMarkdown>
          </Box>
        </Box>

        {submittedAnswers.length > currentQuestionIndex &&
          submittedAnswers[currentQuestionIndex].trim() !== "<p><br></p>" && (
            <Box display="flex" alignItems="center" mb={2}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bgcolor="#1976d2"
                color="white"
                width={40}
                height={40}
                mr={2}
              >
                <UserAvatar
                  avatar={userProfile?.avatar}
                  fullName={userProfile?.full_name}
                />
              </Box>
              <Box className="submitted-answer" ml={2}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: submittedAnswers[currentQuestionIndex],
                  }}
                />
              </Box>
            </Box>
          )}

        <Box className="editor-container">
          <Typography style={{ paddingTop: "6px" }}>
            Write your answer
          </Typography>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
        <Box my={2}>
          <ReactQuill
            value={answer}
            onChange={handleAnswerChange}
            modules={modules}
            className="react-quill"
            style={{ paddingBottom: "20px" }}
          />
        </Box>
        <Toaster
          open={toaster.open}
          message={toaster.message}
          severity={toaster.severity}
          onClose={handleCloseToaster}
        />
      </div>
    </div>
  );
};

export default SubjectiveQuestion;

import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

const PreferenceNav = ({ setLanguage, initialLanguage }) => {
  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage); 
  };

  return (
    <Navbar bg="light" expand="lg" className="preference-nav">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <NavDropdown
            title={`Language: ${initialLanguage}`}
            id="basic-nav-dropdown"
            className="language-dropdown"
          >
            <NavDropdown.Item onClick={() => handleLanguageChange("python")}>
              Python
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleLanguageChange("javascript")}>
              JavaScript
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleLanguageChange("cpp")}>
              C++
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleLanguageChange("java")}>
              Java
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleLanguageChange("go")}>
              Go
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default PreferenceNav;

import React from "react";
import { Snackbar, Alert } from "@mui/material";

const Toaster = ({ open, message, severity, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{ marginBottom: 2, marginLeft: 2 }} 
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: "auto" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toaster;

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Chip,
  Grid,
  Button,
  Card,
  CardContent,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Loader from "../components/Loader";
import Toaster from "components/Toaster/Toaster";
import SuccessModal from "components/SuccessModal";
import ErrorToast from "components/Toaster/ErrorToast";
import api from "../api";
import { useSelector } from "react-redux";

const CreateNewAssessment = ({ course }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [chipLabels, setChipLabels] = useState([]);
  const [fetchingChips, setFetchingChips] = useState(true);
  const [submitToastOpen, setSubmitToastOpen] = React.useState(false);
  const [submitMessage, setSubmitMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const selectedModuleId = useSelector(
    (state) => state.module.selectedModuleId
  );

  const questionCategories = [
    {
      type: "MCQ",
      levels: [
        { difficulty: "Easy", count: 0 },
        { difficulty: "Moderate", count: 0 },
        { difficulty: "Difficult", count: 0 },
      ],
    },
    {
      type: "Subjective",
      levels: [
        { difficulty: "Easy", count: 0 },
        { difficulty: "Moderate", count: 0 },
        { difficulty: "Difficult", count: 0 },
      ],
    },
    {
      type: "Programming",
      levels: [
        { difficulty: "Easy", count: 0 },
        { difficulty: "Moderate", count: 0 },
        { difficulty: "Difficult", count: 0 },
      ],
    },
  ];

  const [selectedChips, setSelectedChips] = useState([]);
  const [isScreenOne, setIsScreenOne] = useState(true);
  const [isGenerateAssignmentClicked, setIsGenerateAssignmentClicked] =
    useState(false);

  const [questionCounts, setQuestionCounts] = useState(
    questionCategories.reduce((acc, category) => {
      acc[category.type] = category.levels.reduce((levelsAcc, level) => {
        levelsAcc[level.difficulty] = level.count;
        return levelsAcc;
      }, {});
      return acc;
    }, {})
  );

  const handleIncrement = (type, difficulty) => {
    setQuestionCounts((prevCounts) => ({
      ...prevCounts,
      [type]: {
        ...prevCounts[type],
        [difficulty]: Math.min(prevCounts[type][difficulty] + 1, 5),
      },
    }));
  };
  

  const handleDecrement = (type, difficulty) => {
    setQuestionCounts((prevCounts) => ({
      ...prevCounts,
      [type]: {
        ...prevCounts[type],
        [difficulty]: Math.max(prevCounts[type][difficulty] - 1, 0),
      },
    }));
  };

  const handleChipClick = (label) => {
    if (label === "Select All") {
      // Toggle select all
      if (selectedChips.length === chipLabels.length - 1) {
        setSelectedChips([]);
      } else {
        setSelectedChips(
          chipLabels
            .filter((chip) => chip.label !== "Select All")
            .map((chip) => chip.label)
        );
      }
    } else {
      if (selectedChips.includes(label)) {
        setSelectedChips(selectedChips.filter((chip) => chip !== label));
      } else {
        setSelectedChips([...selectedChips, label]);
      }
    }
  };

  const handleNextClick = () => {
    setIsScreenOne(false);
  };

  const handleGenerateAssignmentClick = async () => {
    setLoading(true);

    const selectedSkills = selectedChips
      .map((label) => {
        const skill = chipLabels.find((chip) => chip.label === label);
        return skill ? skill.id : null;
      })
      .filter((id) => id !== null);

    const data = {
      p: selectedSkills.map((skill_id) => ({
        skill_id,
        E: questionCounts.Programming.Easy,
        M: questionCounts.Programming.Moderate,
        H: questionCounts.Programming.Difficult,
      })),
      o: selectedSkills.map((skill_id) => ({
        skill_id,
        E: questionCounts.MCQ.Easy,
        M: questionCounts.MCQ.Moderate,
        H: questionCounts.MCQ.Difficult,
      })),
      s: selectedSkills.map((skill_id) => ({
        skill_id,
        E: questionCounts.Subjective.Easy,
        M: questionCounts.Subjective.Moderate,
        H: questionCounts.Subjective.Difficult,
      })),
    };

    const sumQuestions = (category) =>
      data[category].reduce((acc, item) => acc + item.E + item.M + item.H, 0);

    if (
      sumQuestions("p") > 5 ||
      sumQuestions("o") > 5 ||
      sumQuestions("s") > 5
    ) {
      setSnackbarOpen(true);
      setSnackbarMessage(
        "Total questions for easy, medium, and hard should not exceed 5 for any category."
      );
      setSnackbarSeverity("error");
      setLoading(false);
      return;
    }

    try {
      await api.post(`course/${course?.id}/generate-assessment/`, data);
      setSubmitMessage("Assessment generated successfully!");
      setSnackbarSeverity("success");
      setSubmitToastOpen(true);
      setSuccessMessage("Assessment Generated Successfully!");
      setIsSuccessModalOpen(true);
      setIsGenerateAssignmentClicked(true);
    } catch (error) {
      setSubmitMessage("Error generating assessment.");
      setSnackbarSeverity("error");
      setSubmitToastOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchChipLabels = async () => {
      setFetchingChips(true);

      try {
        const response = await api.get(`workspace/skills/${selectedModuleId}/`);
        const labels = response?.data?.map((skill) => ({
          label: skill.title,
          id: skill.id,
        }));
        setChipLabels([...labels, { label: "Select All", id: null }]);
      } catch (error) {
        <ErrorToast message={`Failed to generate skills: ${error}`} />;
      } finally {
        setFetchingChips(false);
      }
    };

    fetchChipLabels();
  }, []);

  if (fetchingChips) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const totalQuestions = questionCategories.reduce((total, category) => {
    return total + category.levels.reduce((sum, level) => sum + level.count, 0);
  }, 0);

  return (
    <Grid container>
      <Grid item xs={12}>
        {isScreenOne ? (
          <>
            <Box sx={{ width: "100%" }}>
              <Typography my={2} fontFamily={"Be Vietnam Pro"}>
                Select Sub Topics
              </Typography>
              <Box sx={{ width: { xs: "100%", sm: "80%", md: "60%" } }}>
                {chipLabels?.filter((chip) => chip.label !== "Select All")
                  ?.length === 0 ? (
                  <Typography
                    fontFamily={"Be Vietnam Pro"}
                    color="textSecondary"
                  >
                    No skills available
                  </Typography>
                ) : (
                  chipLabels?.map((chip, index) => (
                    <Chip
                      key={index}
                      label={chip.label}
                      variant="outlined"
                      sx={{
                        marginRight: 1,
                        marginBottom: 1,
                        fontFamily: "Be Vietnam Pro",
                        color:
                          selectedChips.includes(chip.label) ||
                          chip.label === "Select All"
                            ? "#0181BB"
                            : "#0181BB",
                        borderColor: selectedChips.includes(chip.label)
                          ? "#0181BB"
                          : "#E0E0E0",
                        cursor: "pointer",
                      }}
                      onClick={() => handleChipClick(chip.label)}
                      deleteIcon={
                        selectedChips?.includes(chip.label) && (
                          <CheckCircleOutlineIcon sx={{ color: "#0181BB" }} />
                        )
                      }
                    />
                  ))
                )}
              </Box>
            </Box>
            <Button
              onClick={handleNextClick}
              sx={{
                mt: 2,
                fontFamily: "Be Vietnam Pro",
                backgroundColor:
                  selectedChips?.length === 0 ? "#B0B0B0" : "#0181BB",
                color: "white",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor:
                    selectedChips?.length === 0 ? "#B0B0B0" : "#01568C",
                },
              }}
              disabled={selectedChips?.length === 0}
            >
              Next
            </Button>
          </>
        ) : (
          <>
            {isGenerateAssignmentClicked && !chipLabels ? (
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      width: "100% !important",
                      gap: "8px",
                      mt: 3,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "#0181BB",
                        color: "white",
                        borderRadius: "50%",
                        width: "32px",
                        height: "32px",
                        textAlign: "center",
                        px: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      E
                    </Box>
                    <Typography
                      sx={{
                        border: "1px solid #D5D5D5",
                        borderRadius: "12px",
                        fontSize: "16px",
                        padding: "12px",
                        flex: 1,
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      You are working on a Dell Boomi process that pulls data
                      from a web service API, transforms it, and then pushes it
                      to a database. Your Boomi process has the following steps:
                      1. HTTP Client connector: Retrieve data from a web service
                      API. 2. Data Process Shape: Transform the retrieved data.
                      The HTTP Client connector and the Data Process Shape
                      appear to be working correctly. Which of the following
                      configurations in the Database connector could be causing
                      this issue?
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    backgroundColor="#F1F5F6"
                    justifyContent="space-between"
                    position={"absolute"}
                    bottom={0}
                    left={0}
                    width={"100%"}
                    height={"90px"}
                    alignItems={"center"}
                    p={2}
                  >
                    <Box
                      sx={{
                        fontSize: "16px",
                        color: "#202124",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      Total Assessment Questions: {totalQuestions}
                    </Box>
                    <Box>
                      <Button
                        sx={{
                          backgroundColor: "#0181BB",
                          color: "white",
                          textTransform: "capitalize",
                          zIndex: 10000,
                          fontFamily: "Be Vietnam Pro",
                        }}
                      >
                        Send
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {selectedChips?.map((chip, index) => (
                    <Box
                      sx={{
                        width: "100%",
                        mt: 2,
                        mb: index === selectedChips.length - 1 ? "90px" : 0,
                      }}
                    >
                      <Card sx={{ width: "100% !important" }}>
                        <CardContent sx={{ backgroundColor: "#F0F0F0" }}>
                          <Typography
                            key={index}
                            sx={{
                              color: "#202124",
                              fontSize: "20px",
                              fontWeight: 600,
                              fontFamily: "Be Vietnam Pro",
                            }}
                          >
                            {chip}
                          </Typography>
                          {questionCategories?.map((category) => (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              mt={2}
                              key={category?.type}
                            >
                              <Card
                                sx={{
                                  ".MuiPaper-root .MuiCard-root": {
                                    width: "100% !important",
                                  },
                                }}
                              >
                                <CardContent
                                  sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      fontFamily: "Be Vietnam Pro",
                                      minWidth: {
                                        lg: 130, // 130px for large screens
                                        md: 130, // 130px for medium screens
                                        sm: 130, // 130px for small screens
                                        xs: "auto", // auto for extra small screens
                                      },
                                    }}
                                  >
                                    {category?.type}
                                  </Box>
                                  {category?.levels?.map((level) => (
                                    <Box key={level.difficulty}>
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          color: "#595A5F",
                                          fontFamily: "Be Vietnam Pro",
                                        }}
                                      >
                                        {level?.difficulty} Questions
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <IconButton
                                          size="small"
                                          sx={{
                                            backgroundColor: "#E5F1F6",
                                            width: "40px",
                                            height: "40px",
                                            p: 1,
                                            borderRadius: 0,
                                            fontFamily: "Be Vietnam Pro",
                                          }}
                                          onClick={() =>
                                            handleDecrement(
                                              category?.type,
                                              level?.difficulty
                                            )
                                          }
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            p: 2,
                                            fontSize: "16px",
                                            fontFamily: "Be Vietnam Pro",
                                          }}
                                        >
                                          {
                                            questionCounts[category?.type][
                                              level?.difficulty
                                            ]
                                          }
                                        </Typography>
                                        <IconButton
                                          size="small"
                                          sx={{
                                            backgroundColor: "#E5F1F6",
                                            width: "40px",
                                            height: "40px",
                                            p: 1,
                                            borderRadius: 0,
                                            fontFamily: "Be Vietnam Pro",
                                          }}
                                          onClick={() =>
                                            handleIncrement(
                                              category?.type,
                                              level?.difficulty
                                            )
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  ))}
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </CardContent>
                      </Card>
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={12}>
                  <Box
                    display="flex"
                    backgroundColor="#F1F5F6"
                    justifyContent="space-between"
                    position="fixed"
                    bottom={0}
                    right={0}
                    width="90%"
                    height="90px"
                    alignItems="center"
                    p={2}
                  >
                    <Box
                      sx={{
                        fontSize: "16px",
                        color: "#202124",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    >
                      Total Assessment Questions: {totalQuestions}
                    </Box>
                    <Box>
                      <Button
                        sx={{
                          backgroundColor: "#0181BB",
                          color: "white",
                          textTransform: "capitalize",
                          fontFamily: "Be Vietnam Pro",
                          zIndex: 10000,
                          "&:hover": {
                            backgroundColor: "#01568C",
                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                          },
                        }}
                        onClick={handleGenerateAssignmentClick}
                      >
                        Generate Assessment
                      </Button>
                    </Box>
                    {loading && <Loader />}
                    <Toaster
                      open={snackbarOpen}
                      message={snackbarMessage}
                      severity={snackbarSeverity}
                      onClose={() => setSnackbarOpen(false)}
                    />
                    <Toaster
                      open={submitToastOpen}
                      message={submitMessage}
                      severity={snackbarSeverity}
                      onClose={() => setSubmitToastOpen(false)}
                    />
                    <SuccessModal
                      open={isSuccessModalOpen}
                      onClose={() => setIsSuccessModalOpen(false)}
                      message={successMessage}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateNewAssessment;

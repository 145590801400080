
import { SET_ONBOARDING_DATA } from './OnboardingActions';

const initialState = {
  data: null,
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ONBOARDING_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default onboardingReducer;

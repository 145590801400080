import React, { useState } from "react";
import "./Navbar.css";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";

const Navbar = ({ staticPage }) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const onboardingData = useSelector((state) => state.onboarding.data);
  const accessToken = localStorage.getItem("access_token");
  const isFormFilled = onboardingData ? onboardingData.form_filled : false;
  const isCallScheduled = onboardingData
    ? onboardingData.call_scheduled
    : false;

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav className="navbar">
      <div className="container w-100">
        <Link to={staticPage? '/about-us': '/'} className="logo-link">
          <div className="logo d-flex justify-content-center align-items-baseline gap-1">
            <img
              src="https://edgentai-public-data.s3.ap-south-1.amazonaws.com/logo.jpeg"
              alt="Logo"
              className="logo-image mr-1"
            />
            Edgent
          </div>
        </Link>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <MenuIcon />
        </div>
        {staticPage ? (
          ""
        ) : (
          <div className={`nav-elements  ${showNavbar && "active"}`}>
            <ul>
              <li>{/* <NavLink to="/">Home</NavLink> */}</li>
              <li>{/* <NavLink to="/blog">Blog</NavLink> */}</li>
              <li>{/* <NavLink to="/projects">Projects</NavLink> */}</li>
              <li>{/* <NavLink to="/about">About</NavLink> */}</li>
              <li>{/* <NavLink to="/contact">Contact</NavLink> */}</li>
              <div
                className="d-flex justify-content-center position-absolute navbuttons"
                style={{ right: "16px" }}
              >
                <div className="px-2">
                  {isFormFilled ? (
                    // If form_filled is true, redirect to '/call-scheduled'
                    <Link
                      to={
                        isFormFilled && !isCallScheduled
                          ? "/calendar"
                          : "/call-scheduled"
                      }
                      className="mb-2"
                    >
                      <Button variant="outline-primary" className="text-lg">
                        Free Career Goal Discussion
                      </Button>
                    </Link>
                  ) : (
                    // If form_filled is false, redirect to '/registration-form'
                    <Link to="/registration-form" className="mb-2">
                      <Button variant="outline-primary" className="text-lg">
                        Free Career Goal Discussion
                      </Button>
                    </Link>
                  )}
                </div>
                <div className="d-flex px-2">
                  {accessToken ? (
                    <Link
                      to={
                        isFormFilled && !isCallScheduled
                          ? "/calendar"
                          : "/call-scheduled"
                      }
                    >
                      <Button size="sm" variant="primary" className="ml-2">
                        Go to Workspace
                      </Button>
                    </Link>
                  ) : (
                    <Link to="/login">
                      <Button size="sm" variant="primary" className="ml-2">
                        Login/ Sign Up
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

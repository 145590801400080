import React from "react";
import { Container, Grid, Typography, IconButton } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";

const Footer = () => {
  return (
    <footer
      style={{
        backgroundColor: "rgba(231, 248, 253, 0.4)",
        color: "#212529",
        padding: "42px",
        marginTop: "auto",
        height: '200px',
      }}
    >
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
                alignItems: 'center'
              }}
            >
              <Typography variant="h6" gutterBottom>
                Connect with us:
              </Typography>
              <IconButton
                href="https://www.linkedin.com/company/edgent-ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn style={{ color: "#212529" }} />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h6" gutterBottom>
              Contact Us:
            </Typography>
            <Typography>Email:  core@edgent.ai</Typography>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;

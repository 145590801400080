import React, { useState } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import goal from "../../assets/contentImages/goal.jpeg";
import goal2 from "../../assets/contentImages/goal2.jpeg";
import mentorMentee from "../../assets/contentImages/mentor-mentee.jpeg";
import './component.scss'

function Steps() {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleAccordionChange = (index) => {
    setActiveSlide(index);
  };

  const cardData = [
    {
      id: 1,
      title: "1. Profile Evaluation and Career Goal Setting",
      body: "We conduct a thorough evaluation of your profile, including education, skills, and work experience. Based on this, we work with you to set clear and achievable career goals.",
    },
    { 
      id: 2, 
      title: "2. Meet Your Mentor", 
      body: "Once your career goals are defined, you'll be matched with a mentor who has expertise in your chosen field. This mentor will guide you through building the skills and knowledge necessary to achieve your goals." 
    },
    { 
      id: 3, 
      title: "3. Pay for Your Preferred Plan", 
      body: "After meeting your mentor and discussing the mentorship plan, choose the plan that best fits your needs. Make the payment to proceed with the mentorship program and start your journey towards achieving your career goals." 
    },
    { 
      id: 4, 
      title: "4. Start Your Mentorship Journey", 
      body: "With your mentorship plan in place and payment made, it's time to kick off your mentorship journey. Engage with your mentor regularly, set learning targets, and make the most out of the personalized guidance provided to reach your career goals." 
    },
  ];

  return (
    <div className="w-100 text-center m-4">
      <h3 className="py-4">Steps</h3>
      <div className="d-flex justify-content-center">
        <div className="w-75 p-4">
          <Accordion
            activeKey={activeSlide.toString()}
            onSelect={(index) => handleAccordionChange(parseInt(index, 10))}
          >
            {cardData.map((item, index) => (
              <Card key={item.id} className="mb-3 elegant-card">
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header className="elegant-header">
                    <strong>{item.title}</strong>
                  </Accordion.Header>
                  <Accordion.Body>{item.body}</Accordion.Body>
                </Accordion.Item>
              </Card>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Steps;

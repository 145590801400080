import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NavbarLoggedInNew from "components/Navbar-new/NavbarLoggedInNew";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <NavbarLoggedInNew hideMenu/>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "#f5f5f5",
          padding: "0 20px",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={(theme) => ({
            fontSize: "8rem",
            fontWeight: "bold",
            color: "#0181BB",
            margin: 0,
            [theme.breakpoints.down("sm")]: {
              fontSize: "5rem",
            },
          })}
        >
          404
        </Typography>
        <Typography
          variant="h2"
          sx={(theme) => ({
            fontSize: "2.5rem",
            fontWeight: 600,
            color: "#333",
            margin: "10px 0",
            [theme.breakpoints.down("sm")]: {
              fontSize: "1.8rem",
            },
          })}
        >
          Page Not Found
        </Typography>
        <Typography
          sx={(theme) => ({
            fontSize: "1.25rem",
            color: "#666",
            maxWidth: "600px",
            lineHeight: 1.6,
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: {
              fontSize: "1rem",
            },
          })}
        >
          Oops! The page you are looking for doesn't exist or has been moved.
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#0181BB",
            color: "#fff",
            padding: "12px 25px",
            fontSize: "1rem",
            textDecoration: "none",
            borderRadius: "5px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            "&:hover": {
              backgroundColor: "#016a9a",
            },
          }}
          onClick={() => navigate("/")}
        >
          Go Back Home
        </Button>
      </Box>
    </>
  );
};

export default PageNotFound;

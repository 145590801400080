import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ConceptBuildingData from "./ConceptBuildingData";
import WrapUpSessionData from "./WrapUpSessionData";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch } from "react-redux";
import { setSelectedModuleId } from "../new-homepage/redux/moduleSlice";
import ErrorToast from "components/Toaster/ErrorToast";
import Toaster from "components/Toaster/Toaster";
import { useSelector } from "react-redux";
import api from "../api";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const ModulesCard = () => {
  const [expandedId, setExpandedId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState("success");
  const { id } = useParams(); // Extract the id from the URL parameters
  const [availableProblems, setAvailableProblems] = useState({});
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.data?.role;

  const handleToasterClose = () => {
    setToasterOpen(false);
  };

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const fetchCourseData = async () => {
    setLoading(true);
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        throw new Error("No access token found in local storage");
      }

      if (!id) {
        throw new Error("No course ID found in URL parameters");
      }

      const response = await api.get(`course/${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response?.data) {
        setCourses(response?.data); // Update the courses state with the fetched data
      } else {
        <ErrorToast message="No data found." />;
      }
    } catch (err) {
      <ErrorToast message={`Failed to fetch course data: ${err}`} />;
    } finally {
      setLoading(false);
    }
  };

  const fetchAvailableProblems = async (moduleId) => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const response = await api.get(
        `course/available-problem-types/${moduleId}/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      setAvailableProblems((prev) => ({
        ...prev,
        [moduleId]: response.data,
      }));
    } catch (err) {
      <ErrorToast
        message={`Failed to fetch available problems for module ${moduleId}: ${err}`}
      />;
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, [id]);

  const toggleAccordion = (courseId) => {
    if (expandedId === courseId) {
      setExpandedId(null);
    } else {
      setExpandedId(courseId);
      dispatch(setSelectedModuleId(courseId));
      if (!availableProblems[courseId]) {
        fetchAvailableProblems(courseId); // Fetch problems only if not already fetched
      }
    }
  };

  const handleToggleComplete = async (event, course) => {
    event.stopPropagation(); // Prevent accordion from toggling
    setLoading(true);
  
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        throw new Error("No access token found in local storage");
      }
  
      if (!course || !course.id) {
        throw new Error("Invalid course data");
      }
  
      // API call to customize-course-topic to toggle is_completed
      await api.patch(
        `course/${course.id}/customize-course-topic/`,
        { is_completed: !course.is_completed }, // Toggle the completion status
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
  
      setToasterMessage(
        course.is_completed
          ? `Module "${course.name}" marked as uncompleted.`
          : `Module "${course.name}" marked as completed.`
      );
      setToasterSeverity("success");
      setToasterOpen(true);
  
      // Fetch the latest data to reflect the changes
      fetchCourseData();
    } catch (err) {
      setToasterMessage(`Failed to update module completion status.`);
      setToasterSeverity("error");
      setToasterOpen(true);
    } finally {
      setLoading(false);
    }
  };
  

  const handleCustomize = async (event, course) => {
    event.stopPropagation(); // Stop event propagation to prevent accordion from opening
    setLoading(true);

    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        throw new Error("No access token found in local storage");
      }

      if (!course || !course.id) {
        throw new Error("Invalid course data");
      }

      await api.post(
        `course/${course.id}/customize-course-topic/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      setToasterMessage("Successfully copied the module");
      setToasterSeverity("success");
      setToasterOpen(true);

      // Fetch the latest data from the server
      fetchCourseData();
    } catch (err) {
      <ErrorToast message="Something went wrong" />;
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (event, course) => {
    event.stopPropagation(); // Stop event propagation to prevent accordion from opening
    setLoading(true);

    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        throw new Error("No access token found in local storage");
      }

      if (!course || !course.id) {
        throw new Error("Invalid course data");
      }

      await api.delete(`course/${course.id}/customize-course-topic/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      setToasterMessage(`Deleted the module: ${course?.name}`);
      setToasterSeverity("error");
      setToasterOpen(true);

      // Fetch the latest data from the server
      fetchCourseData();
    } catch (err) {
      <ErrorToast message="Something went wrong" />;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <CircularProgress />
        </Box>
      ) : courses && courses?.length > 0 ? (
        courses?.map((course, index) => (
          <Grid item xs={12} mb={3} key={course?.id}>
            <Accordion
              sx={{
                "& .MuiAccordionSummary-expandIcon": { display: "none" },
                padding: "8px",
                borderRadius: "10px",
              }}
              p={1}
              expanded={expandedId === course.id}
              onChange={() => toggleAccordion(course.id)}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography
                      sx={{
                        color: "#868686",
                        fontWeight: 400,
                        fontSize: "14px",
                        fontFamily: "Be Vietnam Pro",
                      }}
                      gutterBottom
                    >
                      MODULE {index + 1}
                    </Typography>
                    {userRole === "mentor" ? (
                      <Box
                        display={"flex"}
                        gap={"4px"}
                        onClick={(event) => {
                          if (course.is_copied) {
                            handleDelete(event, course);
                          } else {
                            handleCustomize(event, course);
                          }
                        }}
                      >
                        {course.is_copied ? (
                          <>
                            <Tooltip title="Delete">
                              <DeleteOutlineOutlinedIcon
                                sx={{
                                  color: "#595A5F",
                                  width: "20px",
                                  height: "20px",
                                  fontFamily: "Be Vietnam Pro",
                                }}
                              />
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Tooltip title="Copy">
                              <ContentCopyIcon
                                sx={{
                                  color: "#595A5F",
                                  width: "20px",
                                  height: "20px",
                                  fontFamily: "Be Vietnam Pro",
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              title={
                                course.is_completed
                                  ? "Mark as uncompleted"
                                  : "Mark as completed"
                              }
                            >
                              {course.is_completed ? (
                                <CheckCircleOutlineIcon
                                  sx={{
                                    color: "green",
                                    width: "20px",
                                    height: "20px",
                                    fontFamily: "Be Vietnam Pro",
                                  }}
                                  onClick={(event) =>
                                    handleToggleComplete(event, course)
                                  }
                                />
                              ) : (
                                <RadioButtonUncheckedIcon
                                  sx={{
                                    color: "#595A5F",
                                    width: "20px",
                                    height: "20px",
                                    fontFamily: "Be Vietnam Pro",
                                  }}
                                  onClick={(event) =>
                                    handleToggleComplete(event, course)
                                  }
                                />
                              )}
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>

                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "18px",
                      color: "#202124",
                      fontFamily: "Be Vietnam Pro",
                    }}
                  >
                    {course.name}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    mt={1}
                  >
                    <Chip
                      label={
                        course.is_completed
                          ? "Completed"
                          : course.is_active
                          ? "In Progress"
                          : "Pending"
                      }
                      sx={{
                        color: "#595A5F",
                        fontSize: "14px",
                        borderRadius: "8px",
                        fontFamily: "Be Vietnam Pro",
                      }}
                    />
                    <Box display={"flex"} justifyContent={"end"}>
                      <Button
                        onClick={() => toggleAccordion(course.id)}
                        variant="outlined"
                        sx={{
                          backgroundColor: "#DDF4FF",
                          textTransform: "capitalize",
                          height: "25px",
                          fontFamily: "Be Vietnam Pro",
                        }}
                        size="small"
                      >
                        {expandedId === course.id
                          ? "Hide Details"
                          : "View Details"}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ marginTop: "8px" }}>
                {course.is_last ? (
                  <>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={selectedTab}
                        onChange={handleChangeTab}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary"
                      >
                        <Tab
                          label="A. Concept Building"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        />
                        <Tab
                          label="B. Wrap-up Session"
                          sx={{ fontFamily: "Be Vietnam Pro" }}
                        />
                      </Tabs>
                    </Box>
                    <Box>
                      {selectedTab === 0 && (
                        <ConceptBuildingData
                          course={course}
                          problems={availableProblems[course.id]}
                          fetchCourseData={fetchCourseData}
                        />
                      )}
                      {selectedTab === 1 && (
                        <WrapUpSessionData
                          course={course}
                          problems={availableProblems[course.id]}
                          fetchCourseData={fetchCourseData}
                        />
                      )}
                    </Box>
                  </>
                ) : (
                  <ConceptBuildingData
                    course={course}
                    problems={availableProblems[course.id]}
                    fetchCourseData={fetchCourseData}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Be Vietnam Pro",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            No modules available
          </Typography>
        </Box>
      )}
      <Toaster
        open={toasterOpen}
        message={toasterMessage}
        severity={toasterSeverity}
        onClose={handleToasterClose}
      />
    </>
  );
};

export default ModulesCard;

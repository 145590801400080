import React, { useState } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Box,
  Container,
  Divider,
  Typography,
  ListItemIcon,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import dashboardIcon from "../../../assets/svgImages/dashboardIcon.svg";
import edgentLogo from "../../../assets/svgImages/edgentLogo.svg";
import Loader from "../../../components/Loader";
import edgent from "../../../assets/homepage/edgentWhite.svg";

const NavbarLoggedInNew = ({ hideMenu }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Drawer state
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setLoading(true);
    localStorage.setItem("access_token", "");
    localStorage.setItem("token_expiration", "0");
    handleClose();
    setTimeout(() => {
      setLoading(false);
      navigate("/");
    }, 1000);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        backgroundColor: "#0181BB",
        height: "100%",
        padding: "16px",
        color: "#fff",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ marginBottom: "16px", fontFamily: "Be Vietnam Pro" }}>
        <img
          src={edgent}
          alt="Logo"
          style={{ width: "70px", height: "45px" }}
        />
      </Box>
      <List>
        <ListItem button onClick={() => navigate("/dashboard")}>
          <img
            src={dashboardIcon}
            alt="Dashboard"
            style={{ marginRight: "8px", width: "18px" }}
          />
          <ListItemText
            primary="Dashboard"
            sx={{
              fontFamily: "Be Vietnam Pro",
              ".MuiTypography-root": { fontFamily: "Be Vietnam Pro" },
            }}
          />
        </ListItem>
        <ListItem button onClick={() => navigate("/chat")}>
          <ChatBubbleOutlineOutlinedIcon
            sx={{ color: "white", marginRight: "8px" }}
          />
          <ListItemText
            primary="Chat"
            sx={{
              fontFamily: "Be Vietnam Pro",
              ".MuiTypography-root": { fontFamily: "Be Vietnam Pro" },
            }}
          />
        </ListItem>
        <ListItem button onClick={() => navigate("/profile")}>
          <AccountCircleOutlinedIcon
            sx={{ color: "white", marginRight: "8px" }}
          />
          <ListItemText
            primary="Profile"
            sx={{
              fontFamily: "Be Vietnam Pro",
              ".MuiTypography-root": { fontFamily: "Be Vietnam Pro" },
            }}
          />
        </ListItem>
       
      </List>
    </Box>
  );

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "#fff",
        boxShadow: "0px 3px 3px 0px #0000001A",
        top: 0,
        zIndex: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" , px: 2}}>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between", // Ensures left and right alignment
            alignItems: "center",
            minHeight: "64px !important",
            width: "100%",
          }}
        >
          {/* Left Section: Drawer Menu Icon and Logo */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* Conditionally render MenuIcon based on hideMenu prop */}
            {!hideMenu && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{
                  color: "rgb(134, 134, 134)",
                }}
              >
                <MenuIcon />
              </IconButton>
            )}

            {/* Drawer Implementation */}
            <Drawer
              anchor="left"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              BackdropProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0)" }, // Transparent backdrop
              }}
            >
              {drawerContent}
            </Drawer>

            {/* Logo */}
            <Link to="/" style={{ textDecoration: "none" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img
                  src={edgentLogo}
                  alt="Logo"
                  style={{ width: "90px", height: "45px" }}
                />
              </Box>
            </Link>
          </Box>

          {/* Right Section: Account Icon */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              sx={{
                color: "#868686",
              }}
              onClick={handleClick}
            >
              <AccountCircleOutlinedIcon sx={{ fontSize: 28 }} />
            </IconButton>

            {/* Account Menu */}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={handleClose}
                component={NavLink}
                to="/profile"
                sx={{ fontFamily: "Be Vietnam Pro" }}
              >
                <Avatar sx={{ width: 32, height: 32, mr: 1 }} />
                <Typography sx={{ fontFamily: "Be Vietnam Pro" }}>
                  Profile
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleLogout}
                sx={{ fontFamily: "Be Vietnam Pro" }}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <Typography sx={{ fontFamily: "Be Vietnam Pro" }}>
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Box>
      {loading && <Loader />}
    </AppBar>
  );
};

export default NavbarLoggedInNew;
import React from "react";
import { Avatar } from "@mui/material";

const UserAvatar = ({ avatar, fullName }) => {
  return avatar ? (
    <Avatar sx={{ width: 30, height: 30 }} src={avatar} />
  ) : (
    <Avatar sx={{ width: 30, height: 30, backgroundColor: "#0172A6" }}>
      {fullName?.trim()?.charAt(0)?.toUpperCase()}
    </Avatar>
  );
};

export default UserAvatar;

import { Box, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const TopAlert = ({ onClick }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#EAF2FB", // Light background to match the offerings section
        color: "#0181BB", // Primary color from offerings section
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: 2,
        px: { xs: 2, sm: 4, md: 6 }, // Added responsiveness for padding
      }}
    >
      <Typography
        variant="body2"
        sx={{
          fontFamily: theme.typography.fontFamily, // Use theme's font family
          mr: 1,
          color: "#202124", // Darker text color for readability
          fontSize: { xs: "14px", sm: "16px", md: "18px" }, // Responsive font size
          textAlign: "center",
          fontFamily: 'Be Vietnam Pro',
        }}
      >
        Boost Your Tech Skills with Our Exclusive Program | EMI starting at ₹5,999/month
      </Typography>
      <Button
        variant="contained"
        sx={{
          textTransform: "capitalize",
          backgroundColor: "#0181BB", // Primary color for the button
          color: "white",
          borderRadius: "20px",
          fontSize: { xs: "0.75rem", sm: "0.875rem" },
          padding: "6px 16px",
          ml: 2,
          fontFamily: theme.typography.fontFamily, // Use theme's font family
          "&:hover": {
            backgroundColor: "#006F9B", // Darker shade of blue for hover effect
          },
          fontFamily: 'Be Vietnam Pro',
        }}
        onClick={onClick}
      >
        Apply Now
      </Button>
    </Box>
  );
};

export default TopAlert;

import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { baseURL } from "aws-config";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import api from '../api'

const Questionnaire = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quillValue, setQuillValue] = useState("");
  const selectedModuleId = useSelector((state) => state.module.selectedModuleId);
  const userProfile = useSelector((state) => state.userProfile);
  const userRole = userProfile?.data?.role;

  const fetchQuestions = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        throw new Error("No access token found in local storage");
      }
  
      const endpoint =
        userRole === "mentor"
          ? `course/${selectedModuleId}/mentor/questionnaire/`
          : `course/${selectedModuleId}/mentee/questionnaire/`;
  
      const response = await api.get(endpoint, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      const fetchedQuestions = response.data.map((question) => ({
        ...question,
        answer_text: question.answer_text || "",
      }));
  
      setQuestions(fetchedQuestions);
      setQuillValue(fetchedQuestions[0]?.answer_text || "");
    } catch (err) {
      setError("Failed to fetch questions");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    fetchQuestions();
  }, [selectedModuleId]);

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setQuillValue(questions[currentQuestionIndex + 1]?.answer_text || "");
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setQuillValue(questions[currentQuestionIndex - 1]?.answer_text || "");
  };

  const handleQuillChange = (value) => {
    setQuillValue(value);
  };

  const handleSubmit = async () => {
    if (quillValue.trim() === "") {
      setError("Answer cannot be empty");
      return;
    }
  
    try {
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        throw new Error("No access token found in local storage");
      }
  
      const updatedQuestions = questions.map((question, index) =>
        index === currentQuestionIndex
          ? { ...question, answer_text: quillValue }
          : question
      );
  
      const postURL =
        userRole === "mentor"
          ? `course/${selectedModuleId}/mentor/questionnaire/`
          : `course/${selectedModuleId}/mentee/questionnaire/`;
  
      await api.post(postURL, updatedQuestions, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      setQuestions(updatedQuestions);
      setQuillValue("");
    } catch (err) {
      setError("Failed to submit answers");
    }
  };
  
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography>{error}</Typography>
      </Box>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];
  const isAnswerSubmitted = currentQuestion?.answer_text.trim() !== "";

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <IconButton
          onClick={handlePreviousQuestion}
          disabled={currentQuestionIndex === 0}
          sx={{
            fontSize: "14px !important",
            color: "#595A5F",
            border: "none",
            borderRadius: "6px",
            backgroundColor: '#E0E0E0',
            '&:disabled': {
              backgroundColor: '#E0E0E0',
              color: '#B0B0B0',
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: "16px", color: "#595A5F", mr: 1 , fontFamily: 'Be Vietnam Pro'}} />
          Previous
        </IconButton>
        <IconButton
          onClick={handleNextQuestion}
          disabled={currentQuestionIndex === questions.length - 1}
          sx={{
            fontSize: "14px !important",
            color: "#595A5F",
            border: "none",
            borderRadius: "6px",
            backgroundColor: '#E0E0E0',
            fontFamily: 'Be Vietnam Pro',
            '&:disabled': {
              backgroundColor: '#E0E0E0',
              color: '#B0B0B0',
            fontFamily: 'Be Vietnam Pro',
            },
          }}
        >
          Next
          <ArrowForwardIcon sx={{ fontSize: "16px", color: "#595A5F", ml:1 }} />
        </IconButton>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              gap: "8px",
              mt: 3,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#0181BB",
                color: "white",
                borderRadius: "50%",
                width: "32px",
                height: "32px",
                textAlign: "center",
                px: 1,
              }}
            >
              Q
            </Box>
            <Typography
              sx={{
                border: "1px solid #D5D5D5",
                borderRadius: "12px",
                fontSize: "16px",
                padding: "12px",
                backgroundColor: "#E5F1F6",
              }}
            >
              {currentQuestion?.text}
            </Typography>
          </Box>
        </Grid>
        {currentQuestion?.answer_text && (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: "8px",
                mt: 3,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#0181BB",
                  color: "white",
                  borderRadius: "50%",
                  width: "32px",
                  height: "32px",
                  textAlign: "center",
                  px: 1,
                }}
              >
                A
              </Box>
              <Typography
                sx={{
                  border: "1px solid #D5D5D5",
                  borderRadius: "12px",
                  fontSize: "16px",
                  padding: "12px",
                  backgroundColor: "#E5F1F6",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentQuestion?.answer_text,
                }}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box mt={3} width={"100%"} p={2}>
            <Box
              display={"flex"}
              backgroundColor="#DCDEDE"
              justifyContent={"space-between"}
              alignItems={"center"}
              p={1}
            >
              <Typography sx={{fontFamily: 'Be Vietnam Pro'}}>Write your answer here</Typography>
              <Button
                onClick={handleSubmit}
                sx={{
                  backgroundColor:
                    isAnswerSubmitted || quillValue.trim() === ""
                      ? "#A9A9A9"
                      : "#0181BB",
                  color: "white",
                  textTransform: "capitalize",
                  height: "35px",
            fontFamily: 'Be Vietnam Pro',
                }}
                disabled={isAnswerSubmitted || quillValue.trim() === ""}
              >
                Submit
              </Button>
            </Box>
            <ReactQuill
              theme="snow"
              value={quillValue}
              onChange={handleQuillChange}
              placeholder="Write your answer here..."
              style={{ height: "200px", fontFamily: 'Be Vietnam Pro' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Questionnaire;

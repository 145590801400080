import { Grid } from "@mui/material";
import NavbarLoggedInNew from "components/Navbar-new/NavbarLoggedInNew";
import React from "react";
import WorkspaceSidebarNew from "./WorkspaceSidebar";
import ModulesCard from "./ModulesCard";

function Workspace() {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <NavbarLoggedInNew />
        </Grid>
      </Grid>
      <Grid 
        container 
        spacing={2} 
        p={2} 
        pt={'30px'}
        sx={{
          px: { xs: 2, sm: 4, md: 8 }, 
          height: 'calc(100vh - 64px)', 
          overflow: 'auto' 
        }}
      >
        <Grid item xs={12} sm={4} md={3}>
          <WorkspaceSidebarNew />
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <ModulesCard />
        </Grid>
      </Grid>
    </>
  );
}

export default Workspace;

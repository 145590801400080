import React from "react";
import { Row, Col } from "react-bootstrap";
import { Box } from "@mui/material";
import book from '../../assets/homepage/book.svg';
import building from '../../assets/homepage/building.svg';
import student from '../../assets/homepage/student.svg';
import suitcase from '../../assets/homepage/suitcase.svg';

function WhoIsItForContent() {
  const cardData = [
    { id: 1, title: "Fresh Graduates", subtitle: "Graduates looking for a job in Tech", imgSrc: student },
    { id: 2, title: "University Students", subtitle: "University students targeting Tech roles for career", imgSrc: building },
    { id: 3, title: "Career Switchers", subtitle: "Professionals who wish to pivot career to Tech", imgSrc: suitcase },
    { id: 4, title: "Tech Professionals", subtitle: "Tech professionals looking to upgrade their current role/company", imgSrc: book },
  ];

  return (
    <Box className="w-100" sx={{ fontFamily: 'Be Vietnam Pro', textAlign: 'center', px: 3 }}>
      <Box className="pt-4" sx={{ color: '#0181BB', fontSize: { xs: '32px', sm: '36px', md: '52px' }, fontWeight: 700 }}>
        Who Is It For?
      </Box>
      <Box sx={{ color: '#595A5F', fontSize: { xs: '16px', sm: '18px' }, mb: 4 }}>
        Discover if Edgent is the right fit for your career goals
      </Box>
      <Box className="d-flex justify-content-center">
        <Row style={{ width: '100%', maxWidth: '1200px' }} className="g-4">
          {cardData.map((card) => (
            <Col key={card.id} xs={12} sm={6} md={3}>
              <Box
                className="d-flex flex-column align-items-center"
                sx={{
                  borderRadius: '16px',
                  overflow: 'hidden',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s, box-shadow 0.3s',
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                  padding: '24px',
                  height: '260px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Box
                  sx={{
                    width: 70,
                    height: 70,
                    background: 'linear-gradient(145deg, #E0F4FF, #A6D9FF)',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2,
                  }}
                >
                  <img src={card.imgSrc} alt={card.title} style={{ width: 36, height: 36 }} />
                </Box>
                <Box sx={{ fontSize: '20px', fontWeight: 700, color: '#202124', mb: 1 }}>
                  {card.title}
                </Box>
                <Box sx={{ fontSize: '16px', color: '#595A5F', textAlign: 'center', lineHeight: 1.5 }}>
                  {card.subtitle}
                </Box>
              </Box>
            </Col>
          ))}
        </Row>
      </Box>
    </Box>
  );
}

export default WhoIsItForContent;
